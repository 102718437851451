import { CircularProgress, Stack, Typography } from '@mui/material'

interface Props {
    text: string
}

const LoadingState = (props: Props) => {
    const { text  } = props;
  return (
    <Stack direction="row" alignItems="center">
        <CircularProgress size={16} thickness={6}/>
        <Typography variant="body2" ml={1}>{text}</Typography>
    </Stack>
  )
}

export default LoadingState