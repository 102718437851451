import { Box, Divider, Grid, Paper, SvgIcon, Typography } from "@mui/material";
import NoImage from "../../../assets/No_Image.png";
import { DeviceType } from ".";

export default function TCPDeviceInfo({
  deviceData,
}: {
  deviceData: DeviceType;
}) {
  const protocol: string[] = [];
  const specialFeatures: string[] = [];

  if (deviceData?.tcpDetails.enabled) {
    protocol.push("TCP");
  }

  if (deviceData?.udpDetails.enabled) {
    protocol.push("UDP");
  }

  if (deviceData?.metadata.can) {
    specialFeatures.push("CAN");
  }

  if (deviceData?.metadata.fota) {
    specialFeatures.push("FOTA");
  }

  if (deviceData?.metadata.obd) {
    specialFeatures.push("OBD");
  }

  if (deviceData?.metadata.bluetooth) {
    specialFeatures.push("Bluetooth");
  }
  const protocolData = protocol.join(", ");
  const specialFeaturesData = specialFeatures.join(", ");

  return (
    <Paper
      style={{
        height: "50vh",
        width: "50vw",
        display: "flex",
        flexDirection: "row",
      }}
      elevation={0}
    >
      <Grid
        item
        xs={6}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"30%"}
      >
        <img
          src={deviceData.image.link || NoImage}
          style={{
            height: "150px",
            width: "80%",
          }}
        />
      </Grid>
      <Box
        display={"flex"}
        width={"70%"}
        flexDirection={"column"}
        marginTop={"-5px"}
        padding={"0px 20px"}
      >
        <Grid display={"flex"} alignItems={"baseline"} height={"50px"}>
          <Typography variant="h6">{deviceData?.modelName}</Typography>
        </Grid>
        {deviceData.metadata.description}
        <div style={{ padding: "15px 0px" }}></div>

        {protocolData && (
          <>
            <div style={{ padding: "5px 0px" }}></div>
            <DeviceSpecInfo spec="Protocol" value={protocolData} />
          </>
        )}

        {specialFeaturesData && (
          <>
            <div style={{ padding: "5px 0px" }}></div>
            <DeviceSpecInfo
              spec="Special Features"
              value={specialFeaturesData}
            />
          </>
        )}

        {deviceData?.metadata.spec?.map((spec) => (
          <>
            <div style={{ padding: "5px 0px" }}></div>
            <DeviceSpecInfo spec={spec.specKey} value={spec.specValue} />
          </>
        ))}
      </Box>
    </Paper>
  );
}

function DeviceSpecInfo({ spec, value }: { spec: string; value: string }) {
  return (
    <>
      <Grid display={"flex"}>
        <Grid item xs={6} width={"50%"}>
          {spec}
        </Grid>
        <Grid item xs={6}>
          {value}
        </Grid>
      </Grid>
      <Divider style={{ borderRight: "1px solid #cdcdcd" }} />
    </>
  );
}
