import { IconButton, IconButtonProps, SvgIcon, Tooltip } from '@mui/material'

interface Props {
  icon: any,
  title: string
  buttonProps: IconButtonProps,
  color?: string,
  iconSize?: "small" | "inherit" | "medium" | "large" | undefined
}

const IconWithToolTip = (props: Props) => {
    
    const { icon, title, buttonProps,iconSize="medium", color = '#000' } = props;

  return (
    <Tooltip title={title} >
      <IconButton {...buttonProps}>
        <SvgIcon inheritViewBox fontSize={iconSize} component={icon} sx={{ color }} />
      </IconButton>
    </Tooltip>
  )
}

export default IconWithToolTip;
