import AddBoxIcon from "@mui/icons-material/AddBox";
import CloseIcon from "@mui/icons-material/Close";
import HideImageIcon from "@mui/icons-material/HideImage";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import LoadingState from "../../../components/LoadingState";
import { toast } from "../../../components/Toast";
import AppSidebar from "../../../routes/AppSidebar";
import tcpdevicesService from "../../../services/tcpdevices.service";
import { useStore } from "../../../store";
import FormFields from "./form";
import TCPDeviceInfo from "./tcp-devices-info";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FilterComponent from "../../../components/FilterComponent";
import PageLoader from "../../../components/PageLoader";

export type ImageDetails = {
  valid: boolean;
  imageContentType: string;
  storageKey: string;
  link: string;
  data?: string | ArrayBuffer;
};

export type TcpUdpDetails = {
  enabled: boolean;
  port: number;
};

export type Spec = {
  specKey: string;
  specValue: string;
};

export type Metadata = {
  can: boolean;
  obd: boolean;
  fota: boolean;
  bluetooth: boolean;
  isSendCommand: boolean;
  description: string;
  spec: Spec[];
};

export type DeviceType = {
  id: number;
  modelName: string;
  manufacturer: string;
  image: ImageDetails;
  tcpDetails: TcpUdpDetails;
  udpDetails: TcpUdpDetails;
  metadata: Metadata;
  createdAt: string;
  updatedAt: string;
};

export default function TCPDevices() {
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [deleteDeviceModal, setDeleteDeviceModal] = useState(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initialSelectedDevice: DeviceType = {
    id: 0,
    modelName: "",
    manufacturer: "",
    image: {
      valid: false,
      imageContentType: "",
      storageKey: "",
      link: "",
    },
    tcpDetails: {
      enabled: false,
      port: 0,
    },
    udpDetails: {
      enabled: false,
      port: 0,
    },
    metadata: {
      can: false,
      obd: false,
      fota: false,
      bluetooth: false,
      isSendCommand: false,
      description: "",
      spec: [],
    },
    createdAt: "",
    updatedAt: "",
  };

  const [selectedDevice, setSelectedDevice] = useState<DeviceType>(
    initialSelectedDevice
  );

  const [deviceData, setDeviceData] = useState<DeviceType[]>([]);
  const [deviceCollection, setDeviceCollection] = useState<DeviceType[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string[]>([]);

  useEffect(() => {
    setDeviceData(deviceCollection);
    handleSearchData(searchKeyword)
  }, [deviceCollection]);

  const handleSearchData = (searchData: string[]) => {
    const filteredData = deviceCollection.filter((device) =>
      searchData.every((searchTerm) =>
        device.modelName
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase())
      )
    );
    if (searchData?.length > 0) {
      setDeviceData(filteredData);
    } else setDeviceData(deviceCollection);
    setSearchKeyword(searchData)
  };

  const ModalAction = () => {
    setModalOpen(!modalOpen);
  };

  const InfoModalAction = () => {
    setInfoModal(!infoModal);
  };

  const { dispatch } = useStore();

  async function getTCPDevices() {
    try {
      setIsLoading(true);
      const data = await tcpdevicesService.getAllDevices();
      const sortedData = data?.data?.sort((a: DeviceType, b: DeviceType) => {
        if (a.modelName < b.modelName) {
          return -1;
        }
        if (a.modelName > b.modelName) {
          return 1;
        }
        return 0;
      });
      setDeviceCollection(sortedData);
    } catch (error: any) {
      toast.error(
        dispatch,
        error?.response?.data?.error?.message || "Loading Failed"
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getTCPDevices();
  }, []);

  async function deleteTCPDevice() {
    try {
      const res = await tcpdevicesService.deleteDevice(selectedDevice?.id);
      if (res.status === 200) {
        toast.success(dispatch, "device deleted");
        let newDeviceData = [...deviceCollection];
        newDeviceData = newDeviceData.filter(
          (device) => device.id !== selectedDevice.id
        );
        setDeviceCollection(newDeviceData);
      }
      setDeleteDeviceModal(false);
      setIsDeleteButtonDisabled(false);
    } catch (error: any) {
      return error;
    }
  }

  const handleUpdateDeviceData = (updatedDeviceData: DeviceType) => {
    let newDeviceData = [...deviceData];
    if (isEditable) {
      const index = newDeviceData.findIndex(
        (device) => device.id === selectedDevice?.id
      );
      newDeviceData[index] = updatedDeviceData;
    } else {
      newDeviceData = [...newDeviceData, updatedDeviceData];
    }
    setDeviceCollection(newDeviceData);
  };

  const handleDeleteDevice = () => {
    setIsDeleteButtonDisabled(true);
    deleteTCPDevice();
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <AppSidebar />
      <Box style={{ width: "100%" }}>
        <Toolbar
          sx={{ borderBottom: "1px solid #cdcdcd", gap: "2%" }}
          variant="dense"
        >
          <Typography variant="h6">TCP Devices</Typography>
          <Button onClick={() => setModalOpen(true)}>
            <AddBoxIcon />
            <span style={{ height: "22px" }}>Create Device</span>
          </Button>
        </Toolbar>

        <Grid
          container
          spacing={2}
          style={{ padding: "0px", display: "flex" }}
          gap={1}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px",
            }}
          >
            <TCPDevicesListing
              deviceData={deviceData}
              ModalAction={ModalAction}
              InfoModalAction={InfoModalAction}
              setEditable={setEditable}
              setDeleteDeviceModal={setDeleteDeviceModal}
              setSelectedDevice={setSelectedDevice}
              isLoading={isLoading}
              handleSearchData={handleSearchData}
            />
          </Grid>
        </Grid>

        <Dialog open={infoModal} onClose={InfoModalAction} maxWidth={"xl"}>
          <DialogTitle>Device Info</DialogTitle>
          <Divider />
          <IconButton
            aria-label="close"
            onClick={() => setInfoModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#CB6100",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <TCPDeviceInfo deviceData={selectedDevice} />
          </DialogContent>
        </Dialog>

        <Dialog
          open={deleteDeviceModal}
          onClose={() => setDeleteDeviceModal(false)}
          maxWidth={"xl"}
        >
          <DialogTitle>Delete Device</DialogTitle>
          <DialogContent>
            <Grid>
              <Typography>Do you want to delete this device?</Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: 10,
                }}
              >
                <Button
                  onClick={() => setDeleteDeviceModal(false)}
                  disabled={isDeleteButtonDisabled}
                >
                  cancel
                </Button>
                <Button
                  onClick={handleDeleteDevice}
                  disabled={isDeleteButtonDisabled}
                >
                  Delete
                </Button>
              </div>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog open={modalOpen} onClose={ModalAction} maxWidth={"xl"}>
          <DialogTitle>
            {isEditable ? "Edit Device" : "Create Device"}
          </DialogTitle>
          <Divider />
          <IconButton
            aria-label="close"
            onClick={() => {
              ModalAction();
              setEditable(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#CB6100",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <FormFields
              setModalOpen={setModalOpen}
              setEditable={setEditable}
              isEditable={isEditable}
              deviceData={selectedDevice}
              handleUpdateDeviceData={handleUpdateDeviceData}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}

const TCPDevicesListing = ({
  deviceData,
  isLoading,
  ModalAction,
  InfoModalAction,
  setDeleteDeviceModal,
  setEditable,
  setSelectedDevice,
  handleSearchData,
}: {
  deviceData: DeviceType[];
  isLoading: boolean;
  InfoModalAction: () => void;
  ModalAction: () => void;
  setEditable: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDevice: React.Dispatch<React.SetStateAction<DeviceType>>;
  handleSearchData: (searchData: string[]) => void;
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container justifyContent={"space-between"}>
        <Typography variant="h6" style={{ padding: "20px" }}>
          All Devices
        </Typography>
        <Grid display={"flex"}>
          <FilterComponent
            onFilter={handleSearchData}
            placeholder="Enter model name"
            sx={{ paddingRight: "0px" }}
            inputFieldStyles={{ height: "25px" }}
          />
        </Grid>
      </Grid>

      {isLoading ? (
        <PageLoader />
      ) : (
        <Grid
          container
          style={{ gap: 20, padding: "10px 0px 0px 0px", marginLeft: "20px" }}
        >
          {deviceData?.length === 0 && !isLoading && (
            <Grid container justifyContent={"center"}>
              <Typography>No Devices Found</Typography>
            </Grid>
          )}
          {deviceData?.map((device, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              xl={1}
              key={index}
              minWidth={220}
            >
              <TCPDevice
                device={device}
                InfoModalAction={InfoModalAction}
                ModalAction={ModalAction}
                setEditable={setEditable}
                setDeleteDeviceModal={setDeleteDeviceModal}
                setSelectedDevice={setSelectedDevice}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

interface TCPDeviceProps {
  device: DeviceType;
  InfoModalAction: () => void;
  ModalAction: () => void;
  setEditable: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDevice: React.Dispatch<React.SetStateAction<DeviceType>>;
}

function TCPDevice({
  device,
  InfoModalAction,
  setDeleteDeviceModal,
  ModalAction,
  setEditable,
  setSelectedDevice,
}: TCPDeviceProps) {
  return (
    <Paper
      elevation={4}
      style={{
        height: "300px",
        borderRadius: 2,
        overflow: "hidden",
        margin: "auto",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => {
        InfoModalAction();
        setSelectedDevice(device);
      }}
    >
      <Grid
        container
        style={{
          height: "150px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {device?.image?.valid ? (
          <LazyLoadImage
            src={device.image.link}
            alt={device.modelName}
            style={{
              maxHeight: "150px",
              width: "auto",
              margin: "15px  0px",
            }}
          />
        ) : (
          <HideImageIcon sx={{ fontSize: "5rem" }} />
        )}
      </Grid>

      <Grid
        container
        style={{ justifyContent: "center", height: "30px", marginTop: "20px" }}
      >
        <Typography fontWeight={500}>{device.modelName}</Typography>
      </Grid>

      <Grid
        container
        style={{
          justifyContent: "center",
          height: "50px",
          padding: "0px 10px",
          alignItems: "center",
          textAlign: "center",
          display: "flex",
        }}
      >
        <Typography
          style={{
            maxHeight: "100%", // Limits the height to the Grid container
            overflow: "hidden", // Hides any content exceeding the maxHeight
            textOverflow: "ellipsis", // Adds ellipsis for overflowed content
          }}
        >
          {device.metadata.description}
        </Typography>
      </Grid>

      <Grid
        container
        style={{
          justifyContent: "center",
          height: 50,
          paddingTop: 5,
          gap: 10,
        }}
      >
        <Button
          style={{ border: "1px solid", height: "32px" }}
          onClick={(event) => {
            event.stopPropagation(); // Stop event from bubbling up to parent elements
            ModalAction();
            setEditable(true);
            setSelectedDevice(device);
          }}
        >
          Edit
        </Button>

        <Button
          style={{ border: "1px solid", height: "32px" }}
          onClick={(event) => {
            event.stopPropagation(); // Stop event from bubbling up to parent elements
            setDeleteDeviceModal(true);
            setSelectedDevice(device);
          }}
        >
          Delete
        </Button>
      </Grid>
    </Paper>
  );
}
