import { ActionType, GlobalStateInterface } from './types';
import { initialState } from './index';

const Reducer = (state: GlobalStateInterface, action: ActionType): GlobalStateInterface => {
  switch (action.type) {
    case 'SET_USER_PERMISSIONS':
      return {
        ...state,
        userPermissions: action.payload,
      };

    case 'SET_TENANT':
        return {
            ...state,
            tenantDetails: action.payload,
        }
    case 'SET_DEFAULT_PROVIDER':
      return {
        ...state,
        provider: action.payload,
    }
    case 'SET_TOAST':
      return {
        ...state,
        toastObject: action.payload,
    }  
    case 'SET_SUBSCRIPTION_LIST': {
      return {
        ...state,
        subscriptionList: action.payload,
      }
    }
    case 'SET_SUBSCRIPTION': {
      return {
        ...state,
        selectedSubscriptionId: action.payload,
      }
    }
    case 'SET_SUBSCRIPTION_DETAILS': {
      return {
        ...state,
        subscriptionDetails: action.payload,
      }
    }
    case 'SET_PUBSUB_TOPICS': {
      return {
        ...state,
        pubSubTopics: action.payload,
      }
    }
    case 'SET_SUBSCRIPTION_PROVISION_STATUS': {
      return {
        ...state,
        subscriptionProvisionStatus: action.payload,
      }
    }
    
    case 'SET_SIDEBAR_COLLABSE_STATE': {
      return {
        ...state,
        sidebarCollapsed: action.payload,
      }
    }
    case 'SET_PERSISTENCE':
      return {
        ...state,
        persistenceType: action.payload,
      };
    case 'LOGIN_COMPLETE':
     return {
      ...state,
      loginComplete: action.payload
     };
    case 'PURGE_STATE':
      return initialState;
    default:
      return state;
  }
};

export default Reducer;