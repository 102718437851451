import api from "./api";

class TCPService {
  getAllDevices = () => {
    return api.get("/tcpUdpModel");
  };

  createDevice = (body: any) => {
    return api.post("/tcpUdpModel", body);
  };

  deleteDevice = (deviceId: number) => {
    return api.delete(`/tcpUdpModel/${deviceId}`);
  };

  updateDevice = (body: any) => {
    return api.put(`/tcpUdpModel/${body.id}`, body);
  };
}
export default new TCPService();
