import { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Toolbar, Typography } from '@mui/material';
import TableRowEmptyState from '../../components/TableRowEmptyState';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import { useStore } from '../../store';
import axios from "axios";
import { Link, useParams } from 'react-router-dom';
import subscriptionsService from '../../services/subscriptions.service';
import RenderElement from '../../components/RenderElement';
import Pagination from '../../components/Pagination';
interface UserData {
    users: string[];
    totalCount: number;
  }
  
  interface Props {
    userData: UserData;
    pageChanged: Function
  }

function UserSubscription(props: Props) {
    const { userData } = props
    const { users,totalCount } = userData;
    const { selectedSubscriptionId } = useParams()
    const SubscriptionTableHeader = [
        { key: 'email', title: 'Email', width: '25%', align: 'left' },
        { key: 'lastactive', title: 'Last Activity', width: '25%', align: 'left' },
        { key: 'createdby', title: 'Created By', width: '25%' },
        { key: 'createdon', title: 'Created on', width: '25%', align: 'left' },
        // { key: 'updatedon', title: 'Updated on', width: '15%', align: 'left' },
        // { key: 'disabled', title: 'Disabled', width: '16%', align: 'left' },

    ]

    return (
        < Box sx={{ flexGrow: 1 }}>
            <TableContainer>
                <Table sx={{ tableLayout: 'fixed' }} size="small" aria-label="Subscription table">
                    <TableBody>
                    <TableRow>
                                {SubscriptionTableHeader.map((headerCell: any) => (
                                    <TableCell sx={{fontWeight:"600",fontSize:"15px",backgroundColor:"#ebebeb"}} key={headerCell.key} align={headerCell.align || 'left'} width={headerCell.width}>
                                        {headerCell.title}
                                    </TableCell>
                                ))}
                            </TableRow>
                        {users?.map((row: any) => {
                            return (
                                <TableRow
                                    key={row?.uid}
                                >

                                    <TableCell align='left'>{row?.email}</TableCell>
                                    <TableCell align='left'>{row?.last_seen ? new Date(row?.last_seen)?.toLocaleString() : ""}</TableCell>
                                    <TableCell className="text-truncate">
                                        {row?.created_by}
                                    </TableCell>
                                    <TableCell align='left'>{row?.created_on ? new Date(row?.created_on)?.toLocaleString(): ""}</TableCell>
                                    {/* <TableCell align='left'>{row?.updated_on}</TableCell> */}
                                    {/* <TableCell align='left'>{row?.disabled}</TableCell> */}
                                </TableRow>
                            )
                        }
                        )}
                    </TableBody>
                </Table>
                <RenderElement show={totalCount > 10}>
            <Pagination
               count={Math.ceil(totalCount/10)}
            //    page={pageNumber}
               onChange={(e, page: number)=> props.pageChanged(page)}
            />
            </RenderElement>
            </TableContainer>

        </Box>
    )
}

export default UserSubscription