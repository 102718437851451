import adminAPI from "./adminAPI";
class TenantService {

    getAllTenants = (pageNumber: number=1,pageSize: number=10) => {
        return adminAPI.get(`/tenants?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }

    createPartnerTenant = (body: any) => {
        return adminAPI.post(`/tenants/create-partner-tenant`,body)
    }
}

export default new TenantService();