import { Dialog,DialogTitle,DialogContent, DialogActions, Grid, Button,CircularProgress,Stack,Alert } from '@mui/material'
import { FormEvent, useState } from 'react';
import { Form, useForm } from '../../components/useForm';
import Controls from '../../components/controls/Controls';
import tenantsService from '../../services/tenants.service';

interface Props {
  open: boolean,
  handleClose: () => void,
  onComplete: () => void,
}

const CreatePartnerModal = (props: Props) =>{
  const { open, handleClose,onComplete } = props;
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  const closeHandler = () => {
    resetForm();
    handleClose();
    setErrorText('');
}

const { values, resetForm, handleInputChange } = useForm({ });

const submitHandler = (e: FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  createPartner();
}

const createPartner = async() => {
  try {
    const data = {
      ...values,
    }
    setLoading(true);
    setErrorText('');
    const createPartner = await tenantsService.createPartnerTenant(data);
    onComplete();
    setLoading(false);
    closeHandler();
  } catch (error: any) {
    console.log(error)
    setLoading(false);
     if(error?.response?.data?.message){
     setErrorText(error?.response?.data?.message)
    }
  }
}

 return (
  <Dialog open={open} onClose={closeHandler} fullWidth maxWidth="sm">
  <DialogTitle>Create Partner</DialogTitle>
  <DialogContent>
    <Form onSubmit={submitHandler}>
      <Grid mt={2}>
        <Grid sm={12}>
        <Controls.Input
                            name="domainName"
                            value={values?.domainName ||''}
                            label="Domain Name"
                            size="small"
                            onChange={handleInputChange}
                            sx={{ marginBottom: 3 }}
                            required
                            fullWidth
                        />
        <Controls.Input
                            name="displayName"
                            value={values?.displayName ||''}
                            label="Display Name"
                            size="small"
                            onChange={handleInputChange}
                            sx={{ marginBottom: 3 }}
                            required
                            fullWidth
                        />
        {
                            errorText && (
                                <Stack sx={{ width: '100%', mb: 1 }} spacing={2}>
                                    <Alert severity="error">{errorText}</Alert>
                                </Stack>
                            )
                        }
        <DialogActions>
                    <Button onClick={closeHandler} sx={{ marginRight: '1rem' }}>Cancel</Button>
                    {
                        !loading &&
                        <Button type="submit"variant="contained"disabled={loading}>
                            Create
                        </Button>
                    }
                    {
                        loading  && <CircularProgress thickness={6} sx={{ marginRight: '.5rem' }} size={18} />
                    }
                </DialogActions>
        </Grid>
      </Grid>
    </Form>
  </DialogContent>
  </Dialog>
 )
}

export default CreatePartnerModal;