import axios, { AxiosInstance } from 'axios';
import ENV from '../config/env';
import { auth } from '../config/firebase';

// const KEY = ``
class phantomApi {
    phntm: AxiosInstance
    constructor() {

        this.phntm = axios.create({
            baseURL: ENV.PHANTOM_API, // 'http://localhost:8000'
        })

        this.phntm.interceptors.request.use(
            async (config: any) => {
                const token = await auth.currentUser?.getIdToken()
                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token
                }
                config.headers['Content-Type'] = 'application/json';
                return config
            },
            error => {
              Promise.reject(error)
            }
        )
    }

    get(url: string) {
        const URL = url
        return this.phntm.get(URL);
    }

    post(url: string, params: any) {
        const URL = url
        return this.phntm.post(URL, params);
    }

    put(url: string, data?: any) {
        const URL = url
        return this.phntm.put(URL, data);
    }

    patch(url: string, data?: any) {
        const URL = url
        return this.phntm.patch(URL, data);
    }

    delete(url: string, data?: any) {
        const URL = url
        return this.phntm.delete(URL, {
            data
        });
    }
}

export default new phantomApi();
