import { Box, Typography } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import subscriptionsService from '../../services/subscriptions.service';
import { useParams } from 'react-router-dom';
import TimeRangeSelector from '../../components/TimeRangeSelector';

const areaChartFill = {
	type: 'gradient',
	gradient: {
		shadeIntensity: 1,
		inverseColors: false,
		opacityFrom: 0.5,
		opacityTo: 0,
		stops: [0, 90, 100]
	}
}
const ChartOptions: ApexOptions = {
    chart: {
			type: 'area',
			id: 'omnicore-usage-chart',
			stacked: false,
			zoom: {
				type: 'x',
				enabled: false,
				autoScaleYaxis: true
			},
			toolbar: {
				show:false
			}
    },
    dataLabels: {
    	enabled: false
    },
    markers: {
    	size: 0,
    },
	fill: areaChartFill,
    yaxis: {
		title: {
			text: 'Message Size (MB)',
		},
    },
    xaxis: {
    	type: 'datetime',
		labels: {
			datetimeUTC: false,
		}
    },
    tooltip: {
    	shared: false,
		x: {
			format: 'dd MMM, hh:mm TT'
		},
    }
}

const UsageDetails = () => {
  const { selectedSubscriptionId } = useParams();
  const [chartData, setChartData] = useState<any[]>([]);
  const [selectedTime, setSelectedTime] = useState('604800000') // 7 day
  // const [chartType, _setChartType] = useState<'bar' | 'area'>('area');

  const getHourlyUsage = async () => {
    try {
      const endTime = Date.now();
      const startTime = endTime - Number(selectedTime);
      const resp = await subscriptionsService.getUsage(selectedSubscriptionId || '', startTime, endTime);
      if(resp?.data?.data) {
        const chartData = resp?.data?.data?.map((d: any) => ({ x: d?.timestamp, y: d?.usage_reading }));
        setChartData(chartData);
      }
    } catch (error) {
      
    }
  }

  useEffect(() => {
    getHourlyUsage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTime])
  

  return (
    <Box p={2} flexGrow={1} width="100%">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">Billable Usage</Typography>
        <TimeRangeSelector selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
      </Box>
 	    <ReactApexChart
				series={[{name: 'No of messages', data: chartData }]}
				options={ChartOptions} type={'area'} height="300px"
			/>
    </Box>
  )
}

export default UsageDetails;
