import { initializeApp } from "firebase/app";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  SAMLAuthProvider,
} from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import {
  getRemoteConfig,
  getAll,
  getValue,
  fetchAndActivate,
} from "firebase/remote-config";

import ENV from "../config/env";

const firebaseConfig = {
  apiKey: ENV.FIREBASE_API_KEY,
  authDomain: ENV.FIREBASE_AUTH_DOMAIN,
  storageBucket: ENV.FIREBASE_STORAGE_BUCKET,
  projectId: ENV.FIREBASE_PROJECT_ID,
  appId: ENV.FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const storage = getStorage(app);

const uploadFile = async ( file: File, filePath: string, progressCallback: any ) => {

  return await new Promise<string>((resolve, reject) => {
    const storageRef = ref(storage, filePath);
    const metadata = {
      contentType: file.type,
    };

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progressCallback(progress);
      },
      (error) => {
        reject(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            resolve(downloadURL);
          })
          .catch((error: any) => {
            reject(error);
          });
      }
    );
  });
};

const provider = new GoogleAuthProvider();
const microsoft = new OAuthProvider("microsoft.com");

const signOutHandler = () => {
  signOut(auth)
    .then((resp) => {
      console.log("Sign out successfully", resp);
    })
    .catch((err) => {
      console.log("Sign out error", err);
    });
};

onAuthStateChanged(auth, async (user) => {
  if (user) {
    const token = await user?.getIdToken();
    localStorage.setItem("token", token);
  } else {
    localStorage.removeItem("token");
  }
});

const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

remoteConfig.defaultConfig = {
  BillingEnabled: '{"subscriptions": []}',
  DashboardEnabled: '{"subscriptions": []}',
};

const fetchAndActivateRemoteConfig = () => {
  fetchAndActivate(remoteConfig).then(() => {
    const allConfig = getAll(remoteConfig);
    const a = getValue(remoteConfig, "BillingEnabled");
    console.log("all config", allConfig, a);
  });
};

const getRemoteConfigValue = (param: string) => getValue(remoteConfig, param);

export {
  app,
  auth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  provider,
  microsoft,
  SAMLAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOutHandler,
  uploadFile,
  remoteConfig,
  fetchAndActivateRemoteConfig,
  getRemoteConfigValue,
};
