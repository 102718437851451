import { CssBaseline } from '@mui/material';

import { BrowserRouter, useLocation } from 'react-router-dom';

import Header from '../components/header/header';
import Toast from '../components/Toast';
import AppRoutes from '../routes/app-routes';
import { GlobalStore } from '../store';
import styles from './App.module.scss';

const AppLayout = () => {
  const location = useLocation();

  return (
    <>
         {
        !['/login', '/reset-password'].includes(location.pathname) &&
        <Header />
      }
      <main id={styles.MainContainer}>
        <AppRoutes />
      </main>
    </>
  )
}


function App() {
  return (
    <GlobalStore>
      <BrowserRouter>
          <CssBaseline />
            <AppLayout />
          <Toast />
      </BrowserRouter>
    </GlobalStore>
  );
}

export default App;
