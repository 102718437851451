import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { AddBox,Done,Close } from "@mui/icons-material";

import RoutePaths from "../../routes/route";

import TableRowEmptyState from "../../components/TableRowEmptyState";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import Pagination, { usePagination } from "../../components/Pagination";
import RenderElement from "../../components/RenderElement";
import { useStore } from "../../store";
import { permissions } from "../../global/constants";
import axios from "axios";
import AppSidebar from "../../routes/AppSidebar";
import tenantsService from "../../services/tenants.service";
import CreatePartnerModal from "./CreatePartnerModal";

const Tenant = () => {
  const [Tenant, setTenant] = useState<any>([]);
  const [apiData, setApiData] = useState<any>([]);
  const { totalCount, setTotalCount, pageNumber, setPageNumber } =
    usePagination({});
  const {
    globalState: { selectedSubscriptionId, userPermissions },
  } = useStore();
  const [loading, setLoading] = useState(false);
  const [openPartnerModal, setOpenPartnerModal] = useState(false);
  const navigate = useNavigate();
  var data = [{ id: "korewireless", numberOfSubscriptions: 5 }];
  // const [queryParams] = useSearchParams();
  // const subscription = queryParams.get('subscription') || '';
  // 

  const getTenants = async (pageNumber: number, loadingState?: boolean) => {
    try {
      if (loadingState) setLoading(true);
      const resp = await tenantsService.getAllTenants(pageNumber,
        10);
      setApiData(resp.data.data);
      setTotalCount(resp.data?.totalCount);
      if (loadingState) setLoading(false);
    } catch (error) {
      if (loadingState) setLoading(false);
    }
  };

  // const createPartnerClick = () => {
  //   setOpenPartnerModal(true);
  // }

  const onPartnerCreationComplete = () => {
    refreshList(pageNumber,true);
  }
   
  const refreshList = (pageNumber: number, loadingState?: boolean) => {
    getTenants(pageNumber,loadingState);
  }

  useEffect(() => {
    getTenants(pageNumber, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const tenantTableHeader = [
    { key: "id", title: "Tenant ID", width: "25%" },
    { key: "Domainname", title: "Domain name", width: "25%", align: "left" },
    { key: "DisplayName", title: "Display Name", width: "25%", align: "left" },
    {
      key: "noOfSubcriptions",
      title: "No Of Subscriptions",
      width: "25%",
      align: "left",
    },
    {
        key: "type",
        title: "Type",
        width: "25%",
        align: "left",
    }
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <AppSidebar />
      <Box sx={{ flexGrow: 1 }}>
        <Toolbar variant="dense">
          <Typography
            variant="h6"
            className="text-truncate"
            fontWeight={500}
            component="h1"
          >
            Tenants
          </Typography>
          {/* <RenderElement show={userPermissions.includes(permissions.CreatePartnerTenant)}>
          <Button sx={{ marginLeft: '3rem' }} onClick={createPartnerClick}>
                        <AddBox sx={{ mr: .5 }} />
                        Create Partner Tenant
                    </Button>
          </RenderElement> */}
        </Toolbar>
        <Divider sx={{ opacity: 1 }} />
        {/* <Toolbar sx={{ borderBottom: '1px solid #cdcdcd' }} variant="dense">
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <RouterLink to="/" style={{ color: '1px solid #cdcdcd', textDecoration: 'none' }}>
                            <Typography style={{ backgroundColor: '#ebebeb', color: '#cb6100', padding: '2px 10px', borderRadius: '30px' }}>Tenants</Typography>
                        </RouterLink>
                    </Breadcrumbs>
                </Toolbar> */}
        <TableContainer>
          <Table
            sx={{ tableLayout: "fixed" }}
            size="small"
            aria-label="Tenant table"
          >
            <TableBody>
              <TableRow>
                {tenantTableHeader.map((headerCell: any) => (
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      fontSize: "15px",
                      backgroundColor: "#ebebeb",
                    }}
                    key={headerCell.key}
                    align={headerCell.align || "left"}
                    width={headerCell.width}
                  >
                    {headerCell.title}
                  </TableCell>
                ))}
              </TableRow>
              <TableRowEmptyState
                show={apiData?.length === 0}
                message="No Tenants"
                loading={loading}
                loadingText="Loading Tenants"
                colSpan={6}
              />
              {apiData?.map((row: any) => {
                return (
                  <TableRow key={row?.tenantId}>
                    <TableCell className="text-truncate">
                      {row.subscriptionCount >= 1 ? (
                        <Link
                          underline="hover"
                          color="inherit"
                          component={RouterLink}
                          to={`${row?.tenantId}/subscriptions`}
                        >
                          {row?.tenantId}
                        </Link>
                      ) : (
                        <span>{row?.tenantId}</span>
                      )}
                    </TableCell>
                    <TableCell className="text-truncate">
                      {row?.domainName}
                    </TableCell>
                    <TableCell className="text-truncate">
                      {row?.tenantName}
                    </TableCell>
                    {/* <TableCell className="text-truncate">
              {row?.tenent_id}
          </TableCell> */}
                    <TableCell align="left">{row?.subscriptionCount}</TableCell>
                    <TableCell align="left">{row?.isOnlyPartner ? "Partner Tenant" : "Tenant"}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <RenderElement show={totalCount > 10}>
          <Pagination
            count={Math.ceil(totalCount / 10)}
            page={pageNumber}
            onChange={(e, page: number) => setPageNumber(page)}
          />
        </RenderElement>
        </TableContainer>
      </Box>
      <CreatePartnerModal
      open={openPartnerModal}
      handleClose={()=> setOpenPartnerModal(false)
      }
      onComplete={onPartnerCreationComplete}
      />
    </Box>
  );
};

export default Tenant;
