import { useEffect, useState } from 'react';
import { Box, Tab, Tabs, Toolbar, Typography, Grid, Divider, Breadcrumbs } from '@mui/material';

import AppSidebar from '../../routes/AppSidebar';
import TabPanel from '../../components/TabPanel';
import UserSubscription from './UserSubscription';
import SubscriptionData from './SubscriptionData';
import { Link, useParams } from 'react-router-dom';
import subscriptionsService from '../../services/subscriptions.service';
import Pagination, { usePagination } from '../../components/Pagination';
import metricsService from '../../services/metrics.service';
import UsageDetails from './UsageDetails';

const formatter = new Intl.NumberFormat('en-US', {
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 3, // (causes 2500.99 to be printed as $2,501)
  })
  
const SubscriptionTab = () => {

    const [tabValue, setTabValue] = useState("Usage");
    const [users, setUserData] = useState({users:[],totalCount:0})
    const [metricsData, setMetricsData] = useState<any[]>([])
    const { totalCount, setTotalCount, pageNumber, setPageNumber } = usePagination({});
    const { selectedSubscriptionId,tenantId } = useParams()
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };
    
    useEffect(() => {
        getUsers(pageNumber)
        getMetricsData()
    }, [(pageNumber)]);
    const getUsers = async (pageNumber:number) => {
        try {
            if (selectedSubscriptionId) {
                const response = await subscriptionsService.getAllSubscriptionUsers(selectedSubscriptionId,pageNumber,10);
                const data = response.data;
                setUserData(data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const getMetricsData = async () => {
            try {
              if (selectedSubscriptionId) {
              const response = await metricsService.getSubscriptionMetrics(selectedSubscriptionId);
              const data = response.data;
              setMetricsData(data.details);
              }
            } catch (error) {
              console.error('Error:', error);
            }
        }

    const nextPageHandler = (page: number) => {
        setPageNumber(page)
      }

    return (
        <Box sx={{ display: 'flex' }}>
            <AppSidebar />
            <Box flexGrow={1}>
                <Box>
                    <Toolbar variant="dense">
                        <Typography variant="h6" className="text-truncate" width="60vw" fontWeight={500} component="h1">Subscription Details</Typography>
                    </Toolbar>
                    <Divider sx={{ opacity: 1 }} />
                </Box>
                <Toolbar variant="dense">
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Link to="/" style={{ color: '1px solid #cdcdcd', textDecoration: "none" }}>
                            <Typography color="text.primary">Tenants</Typography>
                        </Link>
                        <Link to={`/tenants/${tenantId}/subscriptions`} style={{ color: '1px solid #cdcdcd', textDecoration: "none" }}>
                            <Typography color="text.primary">{tenantId}</Typography>
                        </Link>
                        <Link to={`/tenants/${tenantId}/subscriptions/${selectedSubscriptionId}`} style={{ color: '1px solid #cdcdcd', textDecoration: "none" }}>
                            <Typography color="text.primary">{selectedSubscriptionId}</Typography>
                        </Link>
                        <Link to={`/tenants/${tenantId}/subscriptions/${selectedSubscriptionId}`} style={{ color: '1px solid #cdcdcd', textDecoration: "none" }} >
                            <Typography style={{ backgroundColor: '#ebebeb', color: '#cb6100', padding: '2px 10px', borderRadius: '30px' }} >{tabValue === "Users" ? "Users" : "Details"} </Typography>
                        </Link>
                    </Breadcrumbs>
                </Toolbar>
                {/* Tabs starting */}
                <Box display={'grid'}>
                    <Box sx={{ padding: '1.5rem 1.5rem 0rem 1.5rem' }} >
                        <Typography variant="h6" marginBottom={3} className="text-truncate" width="60vw" fontWeight={500} component="h1">Subscription ID: {selectedSubscriptionId}</Typography>
                        <Grid container>
                            <Grid item sm={6}>
                                <Divider sx={{ opacity: 1 }} />
                                <Grid container>
                                    <Grid item sm={8}>
                                        <Typography fontWeight="500" variant="subtitle1">Total Users</Typography>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Typography color="GrayText" variant="body1">{users?.totalCount}</Typography>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ opacity: 1 }} />
                                <Grid container>
                                    <Grid item sm={8}>
                                        <Typography fontWeight="500" variant="subtitle1">Last Activity</Typography>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Typography color="GrayText" variant="body1">
                                            -
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ opacity: 1 }} />
                                <Grid container>
                                    <Grid item sm={8}>
                                        <Typography fontWeight="500" variant="subtitle1">No of Devices</Typography>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Typography color="GrayText" variant="body1">{formatter.format(metricsData[0]?.noOfDevices)}</Typography>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ opacity: 1 }} />
                                <Grid container>
                                    <Grid item sm={8}>
                                        <Typography fontWeight="500" variant="subtitle1">Message size</Typography>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Typography color="GrayText" variant="body1" textTransform="capitalize">
                                            {formatter.format(metricsData[0]?.messageSize)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ opacity: 1, marginBottom: 5 }} />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box >
                    <Divider sx={{ opacity: 1 ,marginTop:"15px"}} />

                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                <Tab value="Details" sx={{ paddingX: 3 }} label="Details" />
                                <Tab value="Usage" sx={{ paddingX: 3 }} label="Usage" />
                                <Tab value="Users" sx={{ paddingX: 3 }} label="Users" />
                            </Tabs>
                        </Box>
                        <TabPanel value={tabValue} index="Details">
                            <SubscriptionData metrics={metricsData||{}}/>
                        </TabPanel>
                        <TabPanel value={tabValue} index="Usage">
                            <UsageDetails />
                        </TabPanel>
                        <TabPanel value={tabValue} index="Users">
                            <UserSubscription userData={users} pageChanged={nextPageHandler}/>
                        </TabPanel>
                    </Box>
                </Box>
                {/* Tabs ending */}
            </Box>
        </Box>

    )
}

export default SubscriptionTab;


