import { Alert, Button, Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormEvent, useState } from 'react';
import Controls from '../../components/controls/Controls';

import { Form, useForm } from '../../components/useForm';

import styles from './Login.module.scss';
import KoreLogo from '../../assets/kore_logo.png'

import { signInWithEmailAndPassword, auth } from '../../config/firebase';
import RenderElement from '../../components/RenderElement';
import ENV from '../../config/env';
import { Provider } from '../../types';
import { useStore } from '../../store';
import { toast } from '../../components/Toast';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/auth.service';
import { useAuthState } from 'react-firebase-hooks/auth';

const Login = () => {

	const [errorText, setErrorText] = useState('');
	const [configuredProvider] = useState<Provider | null>(null);
	const [isPasswordReset] = useState(false);

	const navigate = useNavigate();

	const { dispatch } = useStore();
    const [user] = useAuthState(auth);
	const validation = (fieldValues = values) => {
		let temp = { ...errors }
		if (!fieldValues?.email) {
			temp.email = "This field is required."
		} else if (!(/$^|.+@.+..+/).test(fieldValues.email)) {
			temp.email = "Invalid email."
		}
		if (!fieldValues?.password) {
			temp.password = "This field is required"
		}

		setErrors({
			...temp
		})

		if (fieldValues === values)
			return Object.values(temp).every(x => x === "")
	}

	const { handleInputChange, values, errors, setErrors } = useForm({}, false, validation);

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const { email, password } = values;
		setErrorText('');
		auth.tenantId = `${ENV.TENANT_ID}` ? `${ENV.TENANT_ID}` : null;
		signInWithEmailAndPassword(auth, email, password).then((user: any) => {
			onSuccessfullLogin();
			// toast.success(dispatch, 'Login successfully');
		}).catch(error => {
			console.log('Error', error?.code);
			let errorMessage = 'Something went wrong. please try again.'
			if (error?.code === 'auth/wrong-password') {
				errorMessage = 'Invalid credential'
			} else if (error?.code === 'auth/too-many-requests') {
				errorMessage = 'Too many attempt. Please try after sometime'
			} else if (error?.code) {
				errorMessage = error?.code
			}
			toast.error(dispatch, errorMessage)
		})
	}

	const onSuccessfullLogin = async() => {
		if (!isPasswordReset && configuredProvider?.provider === 'password') {
			navigate('/reset-password');
		}

	}

	return (
		<Box className={styles.loginContainer}>
			<Box paddingY={8} sx={{ flex: 1 }}>
				<Grid container alignItems="center" justifyContent="center">
					<Grid item xs={10} sm={6} md={4} lg={4} xl={3.5}>
						<Form className={styles.LoginForm} onSubmit={handleSubmit}>
							<RenderElement show={!!errorText}>
								<Stack sx={{ width: '100%', marginY: '1rem' }} spacing={2}>
									<Alert severity="error">{errorText}</Alert>
								</Stack>
							</RenderElement>
							<img src={KoreLogo} alt="Logo" height={36} style={{ marginBottom: '0.5rem' }} />
							<Grid item marginBottom={3}>
								<Typography component="p" variant='h5' fontWeight={500} marginBottom={2}>Sign in</Typography>
								<Controls.Input
									placeholder="Email"
									name="email"
									type="email"
									required
									onChange={handleInputChange}
									value={values?.email || ''}
									fullWidth
									variant="standard"
									// onKeyDown={emailKeyDownHandler}
									error={!!errors.email}
									helperText={errors.email}
								/>
							</Grid>

							<Grid item marginBottom={3}>
								<Controls.Input
									placeholder="Password"
									name="password"
									type="password"
									value={values?.password || ''}
									onChange={handleInputChange}
									fullWidth
									variant="standard"
								/>
							</Grid>
							<Grid item alignSelf="flex-end" marginY={2}>
								<Button type="submit" variant="contained" sx={{ borderRadius: 0 }}>Login</Button>
							</Grid>
						</Form>
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
}

export default Login;
