import { Summarize, Sync } from "@mui/icons-material";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import SidebarCollapseButton from "../components/SidebarCollapseButton";
import { useStore } from "../store";
import ScienceIcon from "@mui/icons-material/Science";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { Person } from "@mui/icons-material";
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';

interface RegistrySideBarProps {
  children: React.ReactNode;
  activeTab: any | undefined;
  setActiveTab: any | undefined;
}
const buttunSx = {
  color: "#000",
  position: "relative",
  // opacity: 0.7,
  py: 0.5,
  paddingX: 1,
  ".MuiListItemText-primary": {
    fontSize: 14,
    fontWeight: 500,
  },
  // '.MuiListItemIcon-root': {
  // 	color: '#000',
  // 	minWidth: 48,
  // 	'.MuiSvgIcon-root': {
  // 		fontSize: 18,
  // 	},
  // },
  ".MuiButtonBase-root": {
    padding: 1.25,
    color: "#000",
    // backgroundColor: '#ececec',
    mr: 2,
    ".MuiSvgIcon-root": {
      fontSize: 18,
    },
  },
  "&.active": {
    color: "#cb6100",
    opacity: 1,
    borderColor: "#fff",
    backgroundColor: "#ebebeb",
    ".openSidebar &::after": {
      content: '" "',
      position: "absolute",
      width: 3,
      height: "65%",
      bgcolor: "#cb6100",
      right: 6,
      borderRadius: 4,
    },
    // '.MuiListItemIcon-root': {
    // 	color: '#cb6100'
    // }
    ".MuiButtonBase-root": {
      color: "#cb6100",
      backgroundColor: "#272727",
    },
  },
};

const AppSidebar = () => {
  const {
    globalState: { selectedSubscriptionId, userPermissions, sidebarCollapsed },
  } = useStore();
  const keyType = "saas";

  return (
    <aside
      id="appSidebar"
      className={[
        "sidebarCommonStyle",
        sidebarCollapsed ? "closedSidebar" : "openSidebar",
      ].join(" ")}
    >
      <SidebarCollapseButton />
      <Toolbar
        sx={{
          pl: sidebarCollapsed ? "8px !important" : 1,
          borderBottom: 1,
          borderColor: "#cdcdcd",
          overflow: "hidden",
          transition: "all .5s",
        }}
        variant="dense"
      ></Toolbar>
      <nav aria-label="main mailbox folders">
        <List disablePadding sx={{ overflow: "hidden" }}>
          <div
            id="phantom"
            aria-valuetext="phantom"
          >
            <ListItem disablePadding>
              <Tooltip title="Phantom" placement="right">
                <ListItemButton
                  component={NavLink}
                  to={`/phantom`}
                  sx={buttunSx}
                >
                  <IconButton>
                    <ScienceIcon fontSize="small" />
                  </IconButton>
                  <ListItemText primary="Phantom" />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </div>
          <div
            id="tenents"
            aria-valuetext="tenents"
          >
            <ListItem disablePadding>
              <Tooltip title="Tenents" placement="right">
                <ListItemButton
                  sx={buttunSx}
                  component={NavLink}
                  to={`/tenants`}
                >
                  <IconButton>
                    <Inventory2Icon fontSize="small" />
                  </IconButton>
                  <ListItemText
                    primary="Tenants"
                    sx={{ whiteSpace: "nowrap" }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </div>
          <div id="users" aria-valuetext="users" >
            <ListItem disablePadding>
              <Tooltip title="Users" placement="right">
                <ListItemButton sx={buttunSx} component={NavLink} to={`/users`}>
                  <IconButton>
                    <Person fontSize="small" />
                  </IconButton>
                  <ListItemText primary="Users" sx={{ whiteSpace: "nowrap" }} />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </div>
          <div
            id="devices"
            aria-valuetext="devices"
          >
            <ListItem disablePadding>
              <Tooltip title="TCP Devices" placement="right">
                <ListItemButton
                  sx={buttunSx}
                  component={NavLink}
                  to={`/devices`}
                >
                  <IconButton>
                    <SettingsRemoteIcon fontSize="small" />
                  </IconButton>
                  <ListItemText
                    primary="TCP Devices"
                    sx={{ whiteSpace: "nowrap" }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </div>
        </List>
      </nav>
    </aside>
  );
};

export default AppSidebar;
