import { Avatar as MuiAvatar } from "@mui/material"
import { stringToColor } from "../utils/utility";

// interface Props {
//   name: string,
// }

const Avatar = (props: any) => {
  const { name } = props;
  const color = stringToColor(name);
  const displayChar = name.charAt(0).toUpperCase();
  return (
    <MuiAvatar alt="Avatar" sx={{bgcolor: color}}>{displayChar}</MuiAvatar>
  )
}

export default Avatar;
