import { TableCell, TableRow } from '@mui/material';
import LoadingState from './LoadingState';

interface Props {
    show: boolean,
    message: string,
    colSpan?: number,
    loading?: boolean,
    loadingText?: string,
}

const TableRowEmptyState = (props: Props) => {
    const { message, show, colSpan, loading, loadingText = 'Loading content...' } = props;
    
    return (
        <> 
            {show ?
                (<TableRow>
                    <TableCell colSpan={colSpan}>
                        { loading ? <LoadingState text={loadingText} /> : message}
                    </TableCell>
                </TableRow>) : null
            }
        </>
    )
}

export default TableRowEmptyState;
