import adminAPI from "./adminAPI";
class SubscriptionService {

    getAllTenantSubscriptions = (tenantId: string,pageNumber: number=1,pageSize: number=10) => {
        return adminAPI.get(`/subscriptions/${tenantId}?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }
    getAllSubscriptionUsers = (subscriptionId: string,pageNumber: number=1,pageSize: number=10) => {
        return adminAPI.get(`/subscriptions/${subscriptionId}/users?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }

    getUsage = (subscriptionId: string, startTime: number, endTime: number) => {
        return adminAPI.get(`/subscriptions/${subscriptionId}/usage?startTime=${startTime}&endTime=${endTime}`);
    }
}

export default new SubscriptionService();