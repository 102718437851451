
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText, SelectProps as MuiSelectProps, SvgIcon } from '@mui/material';

type OptionType = {
    id: string | number,
    title: string,
    disabled?: boolean,
    icon?: any,
    iconColor?: string,
}

interface Props extends Omit<MuiSelectProps, 'value' | 'onChange' | 'children'> {
    options: Array<OptionType>;
    value?: string | number;
    onChange: any;
    error?: boolean;
    helperText?: string;
  }

const labelStyle= { 
    transform: 'translate(14px, 9px) scale(1)',
    '&.MuiFormLabel-filled, &.Mui-focused': {
        transform: 'translate(14px, -9px) scale(0.75)',
    }
}


export default function Select(props: Props) {

    const { name, label, value,error, sx, onChange, options, size, placeholder, helperText, required, disabled, ...rest } = props;

    return (
        <FormControl variant="outlined" fullWidth size={size} error={error}>
            <InputLabel sx={[labelStyle]}>{label}</InputLabel>
            <MuiSelect
                label={label}
                name={name}
                value={value}
                size={size || 'small'}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                sx={sx}
                {...rest}
                onChange={onChange}>
                    
                {
                    !required &&
                    <MenuItem value="">None</MenuItem>
                }
                {
                    options.map(
                        item => (
                        <MenuItem key={item.id} disabled={item?.disabled} value={item.id} sx={{ fontSize: '95%', color: '#000' }}>
                            {item?.icon &&
                                <SvgIcon inheritViewBox component={item?.icon} fontSize="small" sx={{ marginRight: 2, color: item?.iconColor }} />
                            }
                            {item.title}
                        </MenuItem>)
                    )
                }
            
            </MuiSelect>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    )
}
