import { Grid, Stack, Typography, Box } from '@mui/material';
import { FormEvent, useState } from 'react';
import validator from 'validator';

import Controls from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';
import { toast } from '../../components/Toast';
import { useStore } from '../../store';
import userService from '../../services/users.service';
import LoadingButton from '../../LoadingButton';

import styles from '../login/Login.module.scss';
import { useNavigate, useParams } from 'react-router-dom';


const ResetPassword = () => {
    
    const { dispatch } = useStore();

    const [processing, setProcessing] = useState(false);

    const navigate = useNavigate();
    const routerParams = useParams();
    const { token } = routerParams;
    
    const validate = (fieldValues = values, field = '') => {
        let temp = { ...errors }
        const isStrong = validator.isStrongPassword(fieldValues?.password, { minLength: 8, minLowercase: 1, minSymbols: 1, minUppercase: 1, minNumbers: 1  })
        if (validator.isEmpty(fieldValues?.password)) {
            temp.password = "This field is required."
        }else if(fieldValues?.password?.length < 8){
            temp.password = "Password must be at least 8 characters long."
        }else if(!isStrong) {
            temp.password = "Password must contain 1 lowercase, 1 uppercase, 1 number and 1 symbol."
        }else if(temp.password > 128){
            temp.password = "Password should not exceed 128 characters."
        }else {
            temp.password = ""
        }

        if(validator.isEmpty(fieldValues?.confirmPassword)) {
            temp.confirmPassword = "This field is required."
        }else if(fieldValues?.password !== fieldValues?.confirmPassword) {
            temp.confirmPassword = "Password miss match."
        }else {
            temp.confirmPassword = ""
        }

        if(field === '') {
            setErrors(JSON.parse(JSON.stringify(temp)))
        }else {
            setErrors({...errors, [field]: temp[field]  || ''})
        }
        console.log(Object.values(temp).every(x => x === ""));
        
        return Object.values(temp).every(x => x === "")
    }

    const { values, errors, setErrors, handleInputChange } = useForm({ password: '', confirmPassword: '' }, true, validate);

    const submitHandler = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
        if(validate()) {
            setNewPassword(values?.password);
        }
	}


    const setNewPassword = async (password: string) => {
        try {
            
            setProcessing(true);
            const data = {
                password,
                token
            }
            await userService.setPassword(data);
            setProcessing(false);
            navigate('/login');
            
        } catch (error: any) {
            setProcessing(false);
            const errMsg = error?.response?.data?.message || 'Set new password failed'
            toast.error(dispatch, errMsg);
        }
    }


  return (
    <Box className={styles.loginContainer} sx={{ minHeight: 'calc(100vh - 65px)' }}>
        <Box paddingY={8} sx={{ flex: 1}}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={10} sm={6} md={4} lg={4} xl={3.5}>
                    <Form className={styles.LoginForm} onSubmit={submitHandler}>
                        <Typography variant='h5' fontWeight={500} mb={.25}>Set New Password</Typography>
                        <Typography variant="caption" color={"GrayText"}>You will be redirected to login page after set new password.</Typography>
                        <Grid container mt={3}>
                            <Grid item sm={12}>
                                <Controls.Input
                                    name="password"
                                    label="New password"
                                    value={values?.password || ''}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                    type="password"
                                    variant="standard"
                                    error={!!errors['password']}
                                    helperText={errors['password']}
                                    sx={{ marginBottom: 3 }}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Controls.Input
                                    name="confirmPassword"
                                    label="Confirm password"
                                    variant="standard"
                                    value={values?.confirmPassword || ''}
                                    onChange={handleInputChange}
                                    type="password"
                                    required
                                    fullWidth
                                    error={!!errors['confirmPassword']}
                                    helperText={errors['confirmPassword']}
                                    sx={{ marginBottom: 3 }}
                                />
                            </Grid>
                        </Grid>

                        <Stack direction="row" marginTop={4} justifyContent="flex-end" spacing={2}>
                            <LoadingButton loading={processing} type="submit" sx={{ borderRadius: 0 }} variant="contained">Set Password</LoadingButton>
                        </Stack>
                    </Form>
                </Grid>
            </Grid>
        </Box>
    </Box>
  )
}

export default ResetPassword;
