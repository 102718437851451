import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useStore } from '../store';

const SidebarCollapseButton = () => {
    const { globalState: { sidebarCollapsed }, dispatch } = useStore();
    const clickHandle = () => {
        dispatch({
            type: 'SET_SIDEBAR_COLLABSE_STATE',
            payload: !sidebarCollapsed
        })
    }
  return (
    <IconButton
        size="small"
        onClick={clickHandle}
        sx={{ 
            position: 'absolute', right: 0, top: 12, transform: 'translateX(50%)', 
            zIndex: 100, bgcolor: '#fff !important', border: 1, padding: '2px'
        }}
    >
        {
            sidebarCollapsed ? 
            <KeyboardDoubleArrowRight color='primary' sx={{ fontSize: 16 }} /> :
            <KeyboardDoubleArrowLeft color='primary' sx={{ fontSize: 16 }} />
        }
        
    </IconButton>
  )
}

export default SidebarCollapseButton