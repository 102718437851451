import DeleteIcon from "@mui/icons-material/Delete";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ChangeEvent, useEffect, useState } from "react";
import { DeviceType } from ".";
import { toast } from "../../../components/Toast";
import Checkbox from "../../../components/controls/Checkbox";
import Input from "../../../components/controls/Input";
import tcpdevicesService from "../../../services/tcpdevices.service";
import { useStore } from "../../../store";

export default function FormFields({
  setModalOpen,
  setEditable,
  handleUpdateDeviceData,
  isEditable,
  deviceData,
}: {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditable: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateDeviceData: (updatedData: DeviceType) => void;
  deviceData: DeviceType;
  isEditable: boolean;
}) {
  const { dispatch } = useStore();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isEditImage, setIsEditImage] = useState(false);
  const [isDeleteImage, setIsDeleteImage] = useState(false);

  const initialFormData: DeviceType = {
    id: 0,
    modelName: "",
    manufacturer: "",
    image: {
      valid: false,
      imageContentType: "",
      storageKey: "",
      link: "",
      data: "",
    },
    tcpDetails: {
      enabled: false,
      port: 0,
    },
    udpDetails: {
      enabled: false,
      port: 0,
    },
    metadata: {
      can: false,
      obd: false,
      fota: false,
      bluetooth: false,
      isSendCommand: false,
      description: "",
      spec: [],
    },
    createdAt: "",
    updatedAt: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (isEditable) {
      setFormData(deviceData);
    }
  }, [isEditable]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    const file = event.target.files[0];

    if (file && formData && formData?.image) {
      const reader = new FileReader();

      reader.onloadend = function () {
        const binaryString = reader?.result as string | ArrayBuffer;
        if (binaryString !== null && binaryString !== undefined) {
          const encoded = btoa(binaryString.toString());

          formData.image.data = encoded.toString();
          formData.image.valid = true;
          formData.image.imageContentType = file.type;
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleChange = (event: any, index?: number) => {
    const { name, value } = event.target;

    if (name === "specKey" && index !== undefined) {
      const updatedSpec = [...formData.metadata.spec];
      updatedSpec[index] = { ...updatedSpec[index], specKey: value };
      setFormData((prevState) => ({
        ...prevState,
        metadata: {
          ...prevState.metadata,
          spec: updatedSpec,
        },
      }));
    } else if (name === "specValue" && index !== undefined) {
      const updatedSpec = [...formData.metadata.spec];
      updatedSpec[index] = { ...updatedSpec[index], specValue: value };
      setFormData((prevState) => ({
        ...prevState,
        metadata: {
          ...prevState.metadata,
          spec: updatedSpec,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        modelName: name === "modelName" ? value : prevState.modelName,
        metadata: {
          ...prevState.metadata,
          can: name === "can" ? value : prevState.metadata.can,
          obd: name === "obd" ? value : prevState.metadata.obd,
          fota: name === "fota" ? value : prevState.metadata.fota,
          isSendCommand:
            name === "isSendCommand" ? value : prevState.metadata.isSendCommand,
          bluetooth:
            name === "bluetooth" ? value : prevState.metadata.bluetooth,
          description:
            name === "description" ? value : prevState.metadata.description,
        },
        tcpDetails:
          name === "tcp"
            ? { ...prevState.tcpDetails, enabled: value }
            : name === "tcpPort"
            ? { ...prevState.tcpDetails, port: parseInt(value) }
            : prevState.tcpDetails,
        udpDetails:
          name === "udp"
            ? { ...prevState.udpDetails, enabled: value }
            : name === "udpPort"
            ? { ...prevState.udpDetails, port: parseInt(value) }
            : prevState.udpDetails,
      }));
    }
  };

  const addMoreSpec = () => {
    setFormData((prevState) => ({
      ...prevState,
      metadata: {
        ...prevState.metadata,
        spec: [
          ...(prevState?.metadata?.spec || []),
          { specKey: "", specValue: "" },
        ],
      },
    }));
  };

  const handleRemoveSpec = (index: number) => {
    setFormData((prevState) => {
      const updatedSpec = [...prevState?.metadata?.spec];
      updatedSpec.splice(index, 1);
      return {
        ...prevState,
        metadata: {
          ...prevState.metadata,
          spec: updatedSpec,
        },
      };
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsButtonDisabled(true);

    const newDeviceData: DeviceType = { ...formData };

    try {
      let res;
      if (isEditable) {
        res = await tcpdevicesService.updateDevice(newDeviceData);
        toast.success(dispatch, "Device Updated");
      } else {
        res = await tcpdevicesService.createDevice(newDeviceData);
        toast.success(dispatch, "Device Created");
      }

      if (res.status === 200 || res.status === 201) {
        setIsButtonDisabled(false);
        setModalOpen(false);
        newDeviceData.image.valid = res.data.image.valid;
        newDeviceData.image.link = res.data.image.link;
        if (!isEditable) newDeviceData.id = res.data.id;
        handleUpdateDeviceData(newDeviceData);
      }
    } catch (error: any) {
      return error;
    }
  };

  const handleDeleteImage = () => {
    formData.image.valid = false;
    setIsDeleteImage(false);
  };

  return (
    <Box style={{ width: "75vh" }}>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Dialog
          open={isDeleteImage}
          onClose={() => setIsDeleteImage(false)}
          maxWidth={"xl"}
        >
          <DialogTitle>Delete Image</DialogTitle>
          <DialogContent>
            <Grid>
              <Typography>Do you want to delete this image?</Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: 10,
                }}
              >
                <Button
                  onClick={() => setIsDeleteImage(false)}
                  disabled={!isDeleteImage}
                >
                  cancel
                </Button>
                <Button onClick={handleDeleteImage} disabled={!isDeleteImage}>
                  Delete
                </Button>
              </div>
            </Grid>
          </DialogContent>
        </Dialog>

        <Input
          name={"modelName"}
          label={"Device Name"}
          value={formData?.modelName}
          size="medium"
          onChange={(e) => handleChange(e)}
          fullWidth
          required
        />
        <TextField
          name="description"
          label="Description"
          value={formData?.metadata?.description}
          multiline
          minRows={2}
          onChange={(e) => handleChange(e)}
          fullWidth
          required
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <Typography fontWeight={500}>Protocol</Typography>
          <span style={{ display: "flex" }}>
            <Checkbox
              label="TCP"
              onChange={(e) => handleChange(e)}
              name="tcp"
              checked={formData?.tcpDetails?.enabled}
            />
            <Checkbox
              label="UDP"
              onChange={(e) => handleChange(e)}
              name="udp"
              checked={formData?.udpDetails?.enabled}
            />
          </span>
        </div>

        {formData?.tcpDetails?.enabled && (
          <Input
            name="tcpPort"
            label="TCP Port"
            value={formData?.tcpDetails?.port}
            size="medium"
            onChange={(e) => handleChange(e)}
            fullWidth
            type="number"
            required
          />
        )}

        {formData?.udpDetails?.enabled && (
          <Input
            name="udpPort"
            label="UDP Port"
            value={formData?.udpDetails?.port}
            size="medium"
            onChange={(e) => handleChange(e)}
            fullWidth
            type="number"
            required
          />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontWeight={500}>Supporting Features</Typography>
          <Checkbox
            label="CAN"
            onChange={(e) => handleChange(e)}
            name="can"
            checked={formData?.metadata?.can}
          />
          <Checkbox
            label="OBD Capabilities"
            onChange={(e) => handleChange(e)}
            name="obd"
            checked={formData?.metadata?.obd}
          />
          <Checkbox
            label="Bluetooth Capabilities"
            onChange={(e) => handleChange(e)}
            name="bluetooth"
            checked={formData?.metadata?.bluetooth}
          />
          <Checkbox
            label="FOTA Upgrade"
            onChange={(e) => handleChange(e)}
            name="fota"
            checked={formData?.metadata?.fota}
          />
          <Checkbox
            label="Send Command"
            onChange={(e) => handleChange(e)}
            name="isSendCommand"
            checked={formData?.metadata?.isSendCommand}
          />
        </div>

        {!formData?.image?.valid ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              paddingBottom: "10px",
            }}
          >
            <Typography fontWeight={500}>Upload Image</Typography>
            <input
              id="logo-upload"
              type="file"
              accept="image/*"
              onChange={(event) => handleFileChange(event)}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: isEditImage ? "column" : "row",
              gap: "5px",
              paddingBottom: "10px",
              alignItems: isEditImage ? "flex-start" : "center",
            }}
          >
            {isEditImage ? (
              <>
                <Typography fontWeight={500}>Upload New Image</Typography>
                <input
                  id="logo-upload"
                  type="file"
                  accept="image/*"
                  onChange={(event) => handleFileChange(event)}
                />
                <Button onClick={() => setIsEditImage(false)}>Back</Button>
              </>
            ) : (
              <>
                <Button onClick={() => setIsEditImage(true)}>Edit Image</Button>
                <Button onClick={() => setIsDeleteImage(true)}>
                  Delete Image
                </Button>
              </>
            )}
          </div>
        )}

        <Typography fontWeight={500}>Custom Specifications</Typography>
        {formData?.metadata?.spec?.map((specItem, index) => (
          <span key={index} style={{ display: "flex", gap: "10px" }}>
            <Input
              name={"specKey"}
              label={`Key`}
              value={specItem.specKey}
              size="medium"
              onChange={(e) => handleChange(e, index)}
              fullWidth
              required
            />
            <Input
              name={"specValue"}
              label={`Value`}
              value={specItem.specValue}
              size="medium"
              onChange={(e) => handleChange(e, index)}
              fullWidth
              required
            />

            <IconButton size="small" onClick={() => handleRemoveSpec(index)}>
              <DeleteIcon fontSize="medium" />
            </IconButton>
          </span>
        ))}
        <span style={{ display: "flex" }}>
          <Button
            onClick={addMoreSpec}
            style={{
              minWidth: 0,
              padding: 5,
              marginRight: "10px",
            }}
          >
            {formData?.metadata?.spec?.length > 0 ? "Add More" : "Add Spec"}
          </Button>
        </span>

        <span
          style={{
            display: "flex",
            justifyContent: "right",
            gap: "10px",
            paddingTop: "10px",
          }}
        >
          <Button
            variant="outlined"
            style={{ height: "30px" }}
            onClick={() => {
              setModalOpen(false);
              setEditable(false);
            }}
            disabled={isButtonDisabled}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            style={{ height: "30px" }}
            disabled={isButtonDisabled}
          >
            {isEditable ? "Update" : "Create"}
          </Button>
        </span>
      </form>
    </Box>
  );
}
