import { Close, FilterList } from '@mui/icons-material';
import { Box, Chip, Stack } from '@mui/material'
import { KeyboardEvent, ChangeEvent, useState, useRef, useEffect } from 'react'
import RenderElement from './RenderElement';
import IconWithToolTip from './IconWithToolTip';

interface Props {
  placeholder?: string,
  sx?:any,
  inputFieldStyles?:any,
  onFilter: (keywords: string[]) => void
}

const FilterComponent = (props: Props) => {
  const { placeholder, sx, inputFieldStyles, onFilter } = props;

  const [value, setValue] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);
  const didMountRef = useRef<boolean>(false);

  const onDelete = (value: string) => () => {
    const filtered = selectedFilters.filter(k => k !== value)
    setSelectedFilters(filtered);
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValue(value)
  }

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter') {
			setSelectedFilters([...selectedFilters, value]);
      setValue('')
		}    
    if(e.key === 'Backspace' && value === '' && selectedFilters.length) {
      const filtered = selectedFilters.slice(0, -1)
      setSelectedFilters(filtered);
    }
  }

  const clearFilter = () => {
    setValue('');
    setSelectedFilters([]);
  }

  const clickFilterBoxHandler = () => {    
    if(inputRef?.current) {
      inputRef.current?.focus()
    }
  }

  useEffect(() => {
    if(didMountRef.current) {
      onFilter(selectedFilters);
    }
    didMountRef.current = true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters])
  
const inputStyles = { border: 0, outlineColor: '#cb6100',...inputFieldStyles };

  return (
    <Box display="flex" borderTop="1px solid #fafafa" px={2} alignItems="center" maxWidth="100%" sx={sx}>
      <FilterList fontSize='small' sx={{ color: '#000' }} />
      <Stack direction="row" px={1} py={.75} spacing={0.5} rowGap={0.5} flexWrap="wrap" flexGrow={1} onClick={clickFilterBoxHandler} sx={{ cursor: 'text' }}>
        {
          selectedFilters.map(keyword => (<Chip color='primary' size='small' key={keyword} label={keyword} onDelete={onDelete(keyword)} />))
        }
        <input ref={inputRef} placeholder={placeholder} value={value} onChange={onChange} onKeyDown={onKeyDown} style={inputStyles}/>
      </Stack>
      <RenderElement show={!!selectedFilters.length || !!value?.length}>
        <IconWithToolTip icon={Close} iconSize="small" title="Clear Filter" buttonProps={{ size: 'small',  onClick: clearFilter }}  />
      </RenderElement>
    </Box>
  )
}

export default FilterComponent;
