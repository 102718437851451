import { Business, Edit, LockReset, Logout } from "@mui/icons-material";
import { MouseEvent, useState } from "react";
import { permissions } from "../../global/constants";
import { useStore } from "../../store";
import Avatar from "../Avatar";
import ContentCopyIcon from "../ContentCopyIcon";
import RenderElement from "../RenderElement";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";

interface LogoProps {
  size?: number;
  hideEdit?: boolean;
  defaultIconSize?: number;
  logoUrl: string;
  tenantId?: string;
}
const Logo = (props: LogoProps) => {
  const { size = 100, hideEdit = false, defaultIconSize, logoUrl,tenantId } = props;

  const {
    dispatch,
    globalState: { userPermissions,tenantDetails },
  } = useStore();

  return (
    <Box
      sx={{
        height: size,
        width: size,
        borderRadius: "50%",
        background: "#fff",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": { label: { display: "flex !important" } },
      }}
    >
      {logoUrl ? (
        <img
          src={logoUrl}
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "50%",
            objectFit: "contain",
            padding: "2px",
          }}
          alt="logo"
        />
      ) : (
        <Business sx={{ color: "#000", fontSize: defaultIconSize }} />
      )}
      {
                (!hideEdit && userPermissions.includes(permissions.updateTenant)) &&

                <label htmlFor='logo-upload' style={{
                    height: 25, width: 25, borderRadius: '50%', position: 'absolute', top: 0, right: 0,
                    backgroundColor: '#fff', border: '1px solid #cdcdcd', cursor: 'pointer', display: 'none'
                }}>
                    {/* <input type="file" name="" id="logo-upload" onChange={} style={{ display: 'none' }} /> */}
                    <Edit sx={{ margin: 'auto', color: '#000' }} fontSize="small" />
                </label>
            }
    </Box>
  );
};

const AccountMenu = (props: any) => {
  const { user, onLogout, tenantDetails } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    globalState: { selectedSubscriptionId, provider },
  } = useStore();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const resetBtnHandler = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        paddingX={"3px"}
        sx={{
          backgroundColor: "#f1f1f1",
          borderRadius: "40px",
          minWidth: "16rem",
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1}>
          <Logo size={30} hideEdit logoUrl={tenantDetails?.logoUrl || ""} />
          <Box>
            <Typography
              color="black"
              sx={{ textTransform: "capitalize", fontWeight: 500 }}
            >
              {tenantDetails?.displayName?.toLowerCase()}
            </Typography>
            <Typography
              color="black"
              sx={{ fontSize: 13, fontStyle: "italic" }}
            >
              {user?.email}
            </Typography>
          </Box>
        </Stack>
        <IconButton sx={{ padding: 0 }} onClick={handleClick}>
          <Avatar name={user?.email?.split("@")[0] || null} />
        </IconButton>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        sx={{ paddingY: 0 }}
        PaperProps={{
          square: true,
          elevation: 0,

          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            borderRadius: 0,
            minWidth: "20rem",
            mt: 1.5,
            "& .MuiList-root": {
              paddingTop: 0,
            },
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 2,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          style={{
            minHeight: "8rem",
            backgroundColor: "#cb6100",
            flexDirection: "column",
          }}
        >
          <Logo
            defaultIconSize={60}
            logoUrl={tenantDetails?.logoUrl || ""}
            tenantId={user.tenantId}
          />
          <Typography
            variant="h6"
            component="p"
            sx={{
              color: "#fff",
              marginTop: 2,
              marginBottom: 1,
              textTransform: "capitalize",
            }}
          >
            {tenantDetails?.displayName?.toLowerCase()}
          </Typography>
          <Typography sx={{ color: "#fff" }} variant="body2">
            Tenant ID: {user?.tenantId}{" "}
            <ContentCopyIcon text={user?.tenantId} color="#fff" />
          </Typography>
          <Typography sx={{ color: "#fff" }} variant="body2">
            Subscription ID: {selectedSubscriptionId}{" "}
            <ContentCopyIcon text={selectedSubscriptionId} color="#fff" />
          </Typography>
        </MenuItem>
        <MenuItem>
          <Avatar name={user?.email?.split("@")[0]} />
          <Stack direction="column">
            <Typography sx={{ textTransform: "capitalize", fontWeight: 500 }}>
              {user?.email?.split("@")[0]}
            </Typography>
            <Typography sx={{ fontSize: 13, fontStyle: "italic" }}>
              {user?.email}
            </Typography>
          </Stack>
        </MenuItem>
        <Divider sx={{ opacity: 1, marginBottom: 0 }} />
        <RenderElement show={provider === "password"}>
          <MenuItem onClick={resetBtnHandler}>
            <ListItemIcon>
              <LockReset fontSize="small" sx={{ color: "#000" }} />
            </ListItemIcon>
            Reset Password
          </MenuItem>
        </RenderElement>
        <MenuItem onClick={onLogout} sx={{ color: "#f00" }}>
          <ListItemIcon>
            <Logout color="error" fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
