import axios, { AxiosInstance } from "axios";
import ENV from "../config/env";
import { auth } from "../config/firebase";

// const KEY = ``
class AdminApi {
  adm: AxiosInstance;
  constructor() {
    this.adm = axios.create({
      baseURL: ENV.ADMIN_API, // 'http://localhost:8000'
    });

    this.adm.interceptors.request.use(
      async (config: any) => {
        const token = await auth.currentUser?.getIdToken();
        if (token) {
          config.headers["Authorization"] = "Bearer " + token;
        }
        config.headers["Content-Type"] = "application/json";
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }

  get(url: string) {
    console.log(url);

    const URL = url;
    return this.adm.get(URL);
  }

  post(url: string, params: any) {
    const URL = url;
    return this.adm.post(URL, params);
  }

  put(url: string, data?: any) {
    const URL = url;
    return this.adm.put(URL, data);
  }

  patch(url: string, data?: any) {
    const URL = url;
    return this.adm.patch(URL, data);
  }

  delete(url: string, data?: any) {
    const URL = url;
    return this.adm.delete(URL, {
      data,
    });
  }
}

export default new AdminApi();
