import {
  Box,
  Select,
  Card,
  Stack,
  CardContent,
  Grid,
  Toolbar,
  Typography,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import metricsService from "../../services/metrics.service";

const formatter = new Intl.NumberFormat("en-US", {
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 3, // (causes 2500.99 to be printed as $2,501)
});

interface Props {
  metrics: any;
}
const SubscriptionData = (props: Props) => {
  const [selectedOption, setSelectedOption] = useState("Overall");
  const { metrics } = props;
  const [apiData, setApiData] = useState<any>();

  useEffect(() => {
    setApiData(metrics[0]);
  }, [metrics]);

  const options = [
    { id: 1, value: "Overall" },
    { id: 2, value: "Current Month" },
    { id: 3, value: "Previous Month" },
    { id: 4, value: "Last Three Months" },
  ];
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value as string);
    switch (event.target.value) {
      case "Current Month":
        setApiData(metrics[0].detailsForCurrentMonth);
        break;
      case "Previous Month":
        setApiData(metrics[0].detailsForPreviousMonth);
        break;
      case "Last Three Months":
        setApiData(metrics[0].detailsForTwoMonthsAgo);
        break;
      default:
        setApiData(metrics[0]);
    }
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Box bgcolor="#f3f3f3" minHeight="calc(100vh - 112px)" p={2}>
          <Card square elevation={0} sx={{ bgcolor: "#fbfbfb", mb: 2 }}>
            <CardContent sx={{ p: 2 }}>
              <Select
                value={selectedOption}
                size="small"
                autoWidth
                required
                sx={{
                  marginBottom: 2.0,
                  backgroundColor: "#ebebeb",
                  "&:hover": {
                    backgroundColor: "#fafafa",
                  },
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    py: 0.5,
                    ".MuiTypography-root": {
                      fontWeight: 600,
                      ml: 2,
                    },
                  },
                  fieldset: { border: 0 },
                }}
                onChange={handleChange}
              >
                {options.map((item: any) => (
                  <MenuItem
                    key={item.id}
                    value={item.value}
                    sx={{ fontSize: "95%", color: "#000", display: "flex" }}
                  >
                    <Typography component="span" ml={1}>
                      {item.value}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
              <Typography mb={0.5} variant="h6" component="h2" fontWeight={600}>
                Connection Details
              </Typography>
              <Grid container spacing={2} className="count-container">
                <Grid item sm={4} lg={3}>
                  <Card elevation={1}>
                    <CardContent>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Typography fontWeight={700} variant="h5">
                            {formatter.format(
                              apiData?.noOfSuccessfulConnections
                            )}
                          </Typography>
                          <Typography
                            fontWeight={600}
                            variant="body1"
                            color="text.secondary"
                          >
                            Successful connections
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={4} lg={3}>
                  <Card elevation={1}>
                    <CardContent>
                      <Typography fontWeight={600} variant="h5">
                        {formatter.format(apiData?.noOfDisConnections)}
                      </Typography>
                      <Typography
                        fontWeight={600}
                        variant="body1"
                        color="text.secondary"
                      >
                        Disconnections
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={4} lg={3}>
                  <Card elevation={1}>
                    <CardContent>
                      <Typography fontWeight={600} variant="h5">
                        {formatter.format(apiData?.noOfDeviceConnectionsFailed)}
                      </Typography>
                      <Typography
                        fontWeight={600}
                        variant="body1"
                        color="text.secondary"
                      >
                        Device Connections Failed
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={4} lg={3}>
                  <Card elevation={1}>
                    <CardContent>
                      <Typography fontWeight={600} variant="h5">
                        {formatter.format(
                          apiData?.noOfGatewayConnectionsFailed
                        )}
                      </Typography>
                      <Typography
                        fontWeight={600}
                        variant="body1"
                        color="text.secondary"
                      >
                        Gateway Connections Failed
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid display="flex" lg={12}>
            <Card square elevation={0} sx={{ bgcolor: "#fbfbfb", mb: 2 }}>
              <CardContent sx={{ p: 2 }}>
                <Typography
                  mb={0.5}
                  variant="h6"
                  component="h2"
                  fontWeight={600}
                >
                  Message Details
                </Typography>
                <Grid container spacing={2} className="count-container">
                  <Grid item sm={4} lg={3}>
                    <Card elevation={1}>
                      <CardContent>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box>
                            <Typography fontWeight={700} variant="h5">
                              {formatter.format(apiData?.messageSize)}
                            </Typography>
                            <Typography
                              fontWeight={600}
                              fontSize={14}
                              variant="body1"
                              color="text.secondary"
                            >
                              Message Size
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={4} lg={3}>
                    <Card elevation={1}>
                      <CardContent>
                        <Typography fontWeight={600} variant="h5">
                          {formatter.format(apiData?.noOfMessages)}
                        </Typography>
                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          variant="body1"
                          color="text.secondary"
                        >
                          Total Messages
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={4} lg={3}>
                    <Card elevation={1}>
                      <CardContent>
                        <Typography fontWeight={600} variant="h5">
                          {formatter.format(apiData?.noOfEventMessages)}
                        </Typography>
                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          variant="body1"
                          color="text.secondary"
                        >
                          Event Messages
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={4} lg={3}>
                    <Card elevation={1}>
                      <CardContent>
                        <Typography fontWeight={600} variant="h5">
                          {formatter.format(apiData?.noOfStateMessages)}
                        </Typography>
                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          variant="body1"
                          color="text.secondary"
                        >
                          State Messages
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={4} lg={3}>
                    <Card elevation={1}>
                      <CardContent>
                        <Typography fontWeight={600} variant="h5">
                          {formatter.format(apiData?.noOfLoopBackMessages)}
                        </Typography>
                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          variant="body1"
                          color="text.secondary"
                        >
                          LoopBack Messages
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={4} lg={3}>
                    <Card elevation={1}>
                      <CardContent>
                        <Typography fontWeight={600} variant="h5">
                          {formatter.format(apiData?.noOfCommandMessages)}
                        </Typography>
                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          variant="body1"
                          color="text.secondary"
                        >
                          Command Messages
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={4} lg={3}>
                    <Card elevation={1}>
                      <CardContent>
                        <Typography fontWeight={600} variant="h5">
                          {formatter.format(apiData?.noOfConfigMessages)}
                        </Typography>
                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          variant="body1"
                          color="text.secondary"
                        >
                          Config Messages
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={4} lg={3}>
                    <Card elevation={1}>
                      <CardContent>
                        <Typography fontWeight={600} variant="h5">
                          {formatter.format(apiData?.noOfAckMessages)}
                        </Typography>
                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          variant="body1"
                          color="text.secondary"
                        >
                          Ack Messages
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={4} lg={3}>
                    <Card elevation={1}>
                      <CardContent>
                        <Typography fontWeight={600} variant="h5">
                          {formatter.format(apiData?.billableMessageSize)}
                        </Typography>
                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          variant="body1"
                          color="text.secondary"
                        >
                          Billable Message Size
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {/* <Card square elevation={0} sx={{ bgcolor: '#fbfbfb', mb: 2 }}>
            <CardContent sx={{ p: 2 }}>
              <Typography mb={.5} variant="h6" component="h2" fontWeight={600}>Time Taken</Typography>
              <Grid container spacing={2} className="count-container">
                <Grid item sm={4} lg={6}>
                  <Card elevation={0} >
                    <CardContent>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box>
                          <Typography fontWeight={500} variant="h5">
                            {new Date(apiData?.startTime).toLocaleDateString('en-US', {
                              day: 'numeric',
                              month: 'long',
                              year: 'numeric',
                              timeZone: 'UTC' // Set the UTC timezone
                            })}
                          </Typography>
                          <Typography fontWeight={400} variant="h5" color="text.secondary">
                            {new Date(apiData?.startTime).toLocaleTimeString('en-US', {
                              timeZone: 'UTC' // Set the UTC timezone
                            })}
                          </Typography>
                          <Typography fontWeight={600} variant="body1" color="text.secondary">
                            Start Time (UTC)
                          </Typography>
                        </Box>
                      </Box>

                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={4} lg={6}>
                  <Card elevation={0} >
                    <CardContent>
                      <Typography fontWeight={500} variant="h5"> {new Date(apiData?.endTime).toLocaleDateString('en-US', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                        timeZone: 'UTC'
                      })}</Typography>
                      <Typography fontWeight={400} variant="h5" color="text.secondary">{new Date(apiData?.endTime).toLocaleTimeString('en-US', { timeZone: 'UTC' })}</Typography>

                      <Typography fontWeight={600} variant="body1" color="text.secondary">End Time (UTC)</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card> */}
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Card sx={{ bgcolor: "#fbfbfb", mb: 2 }} elevation={0} square>
                <CardContent>
                  <Typography
                    mb={0.5}
                    variant="h6"
                    component="h2"
                    fontWeight={600}
                  >
                    Bytes sent and received
                  </Typography>
                  <Stack direction="row" spacing={2} mb={2}>
                    <Card square elevation={1} sx={{ flex: 1 }}>
                      <CardContent>
                        <Box>
                          <Typography fontWeight={600} variant="h5">
                            {formatter.format(apiData?.bytesSent)}
                          </Typography>
                          <Typography
                            fontWeight={600}
                            variant="body2"
                            fontSize={14}
                            color="text.secondary"
                          >
                            Total bytes sent
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                    <Card square elevation={1} sx={{ flex: 1 }}>
                      <CardContent>
                        <Box>
                          <Typography fontWeight={600} variant="h5">
                            {formatter.format(apiData?.bytesReceived)}
                          </Typography>
                          <Typography
                            fontWeight={600}
                            fontSize={14}
                            variant="body2"
                            color="text.secondary"
                          >
                            Total bytes received
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={6}>
              <Card sx={{ bgcolor: "#fbfbfb", mb: 2 }} elevation={0} square>
                <CardContent>
                  <Typography
                    mb={0.5}
                    variant="h6"
                    component="h2"
                    fontWeight={600}
                  >
                    Billable bytes transferred
                  </Typography>
                  <Stack direction="row" spacing={2} mb={2}>
                    <Card square elevation={1} sx={{ flex: 1 }}>
                      <CardContent>
                        <Box>
                          <Typography fontWeight={600} variant="h5">
                            {formatter.format(apiData?.billableBytesSent)}
                          </Typography>
                          <Typography
                            fontWeight={600}
                            fontSize={14}
                            variant="body2"
                            color="text.secondary"
                          >
                            Total billable bytes sent
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                    <Card square elevation={1} sx={{ flex: 1 }}>
                      <CardContent>
                        <Box>
                          <Typography fontWeight={600} variant="h5">
                            {formatter.format(apiData?.billableBytesReceived)}
                          </Typography>
                          <Typography
                            fontWeight={600}
                            fontSize={14}
                            variant="body2"
                            color="text.secondary"
                          >
                            Total billable bytes received
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default SubscriptionData;
