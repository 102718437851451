import adminAPI from "./adminAPI";
class UserService {

    getAllUsers = (pageNumber: number=1,pageSize: number=10) => {
        return adminAPI.get(`/users?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }

    createUser = (body: any) => {
        return adminAPI.post(`/users`, body);
    }

    setPassword = (body: any) => {
        return adminAPI.post(`/users/set-password`, body);
    }

    deleteUser = (uid: string) => {
        return adminAPI.delete(`/users/${uid}`)
    }
}

export default new UserService();