import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import GroupSelect from "./GroupSelect";
import Checkbox from "./Checkbox";
// import DatePicker from "./DatePicker";
import Button from "./Button";

const Controls = {
    Input,
    RadioGroup,
    Select,
    GroupSelect,
    Checkbox,
    // DatePicker,
    Button
}

export default Controls;