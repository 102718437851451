import { useEffect, useState } from 'react';
import axios from "axios";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import "./PhantomComponent.css"
import LoadingState from '../../components/LoadingState';
import AppSidebar from '../../routes/AppSidebar';
import { Box, Breadcrumbs, Divider, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PhantomService from "../../services/phantom.service"
import phantomService from '../../services/phantom.service';

function ContextAwareToggle({ children, eventKey, callback }: any) {
    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    return (
        <button
            type="button"
            className={"BlockButton"}
            style={{ backgroundColor: 'transparent' }}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
}

const PhantomComponent: React.FC<{}> = props => {

    useEffect(() => {
        getData()
    }, [])

    const [apiData, setApiData] = useState<any[] | null>(null)
    const [metricsData, setMetricsData] = useState<any[] | null>(null)
    const [tcpUdpData, setTcpUdpData] = useState<any[] | null>(null)
    const [activeTab, setActiveTab] = useState<any>('phantom');

    const getData = async () => {
       try {
        const data: any = await phantomService.getPhantomResults();
        setApiData([...data?.data?.apicheck])
        setMetricsData([...data?.data?.metricscheck])
        setTcpUdpData([...data?.data?.tcpUdpCheck])
       } catch (error) {
        console.log("error: "+error)
        setApiData([])
        setMetricsData([])
        setTcpUdpData([])
       }
    }

    return <Box sx={{ display: 'flex' }}>
        <AppSidebar/>
        <Box sx={{ flexGrow: 1 }}>

            <Toolbar sx={{ borderBottom: '1px solid #cdcdcd' }} variant="dense">
                <Typography variant="h6" className="text-truncate" width="60vw" fontWeight={500} component="h1">Test Details</Typography>
            </Toolbar>
            {/* <Toolbar sx={{ padding: "1.5rem 0" }} variant="dense">
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <Link to="/phantom" style={{ color: '1px solid #cdcdcd', textDecoration: "none" }} >
                        <Typography style={{ backgroundColor: '#ebebeb', color: '#cb6100', padding: '2px 10px', borderRadius: '30px' }} >Phantom</Typography>
                    </Link>
                </Breadcrumbs>
            </Toolbar> */}
            <div className='OuterDiv'>
                <div className='PhantomDiv' ><p>Result of last 5 API runs:</p></div>
                {apiData ? apiData.length > 0 ? <div style={{ margin: "10px 3rem" }}>
                    <Accordion flush >
                        {apiData && apiData.map((eachData, mindex) => {
                            var status = Object.values(eachData.status).indexOf('Fail') > -1 ? "Fail" : "Pass"
                            var styleValue = Object.values(eachData.status).indexOf('Fail') > -1 ? { backgroundColor: "rgb(215 143 148)" } : { backgroundColor: "rgb(237 230 230)" }
                            return (
                                <Card key={mindex}>
                                    <Card.Header style={styleValue}>
                                        <ContextAwareToggle eventKey={mindex.toString()} > {mindex + 1}. Status: {status} </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={mindex.toString()} >
                                        <Card.Body>
                                            <div style={{ margin: "5px 0", fontWeight:"600"}}> Subscription Id : {eachData.subscription}</div>
                                            <div style={{ margin: "5px 0", fontWeight:"600" }}> Status : {eachData.completion}</div>
                                            <div className="table-responsive" style={{ height: "30rem", overflowY: "scroll" }}>
                                                <table className="table table-bordered" style={{ background: "white !important" }}>
                                                    <tbody style={{ border: "none" }}>
                                                        {Object.keys(eachData.status).map((key, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td style={{ width: "80%", borderRight: "none" }}>
                                                                        {key}
                                                                    </td>
                                                                    <td style={{ width: "20%", borderLeft: "none" }}>
                                                                        {eachData.status[key]}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            );
                        })}
                    </Accordion></div> : "No Data Available" : <div style={{ height: "10rem", margin: "10px 3rem" }}> <LoadingState text="Loading" /></div>}
                    <div className='PhantomDiv' ><p>Result of last 5 TCP/UDP API runs:</p></div>
                {tcpUdpData ? tcpUdpData.length > 0 ? <div style={{ margin: "10px 3rem" }}>
                    <Accordion flush >
                        {tcpUdpData && tcpUdpData.map((eachData, mindex) => {
                            var status = Object.values(eachData.status).indexOf('Fail') > -1 ? "Fail" : "Pass"
                            var styleValue = Object.values(eachData.status).indexOf('Fail') > -1 ? { backgroundColor: "rgb(215 143 148)" } : { backgroundColor: "rgb(237 230 230)" }
                            return (
                                <Card key={mindex}>
                                    <Card.Header style={styleValue}>
                                        <ContextAwareToggle eventKey={mindex.toString()} > {mindex + 1}. Status: {status} </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={mindex.toString()} >
                                        <Card.Body>
                                            <div style={{ margin: "5px 0", fontWeight:"600"}}> Subscription Id : {eachData.subscription}</div>
                                            <div style={{ margin: "5px 0", fontWeight:"600" }}> Status : {eachData.completion}</div>
                                            <div className="table-responsive" style={{ height: "12rem", overflowY: "scroll" }}>
                                                <table className="table table-bordered" style={{ background: "white !important" }}>
                                                    <tbody style={{ border: "none" }}>
                                                        {Object.keys(eachData.status).map((key, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td style={{ width: "80%", borderRight: "none" }}>
                                                                        {key}
                                                                    </td>
                                                                    <td style={{ width: "20%", borderLeft: "none" }}>
                                                                        {eachData.status[key]}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            );
                        })}
                    </Accordion></div> : "No Data Available" : <div style={{ height: "10rem", margin: "10px 3rem" }}> <LoadingState text="Loading" /></div>}
                <div className='PhantomDiv' ><p>Result of last 5 Usage Metrics Runs:</p></div>
                {metricsData ? metricsData.length > 0 ? <div style={{ height: "50rem", margin: "10px 3rem" }}>
                    <Accordion flush >
                        {metricsData && metricsData.map((data, mindex) => {
                            var styleValue = data.status.indexOf('Fail') > -1 ? { backgroundColor: "rgb(215 143 148)" } : { backgroundColor: "rgb(237 230 230)" }
                            return (
                                <Card key={mindex}>
                                    <Card.Header style={styleValue}>
                                        <ContextAwareToggle eventKey={mindex.toString()} > {mindex + 1}. Status: {data.status} <p></p></ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={mindex.toString()} >
                                        <Card.Body>
                                            <div style={{ margin: "5px 0" }}> Subscription Id : {data.subscriptionId}</div>
                                            <div className="table-responsive table-flex">
                                                <div style={{ width: "50%" }}>
                                                    <div>Total Data sent:</div>
                                                    <table className="table table-bordered" style={{ background: "white !important" }}>
                                                        <tbody style={{ border: "none" }}>
                                                            {data.publishedData ? Object.keys(data.publishedData).map((key: any, index: any) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td style={{ width: "80%", borderRight: "none" }}>
                                                                            {key}
                                                                        </td>
                                                                        <td style={{ width: "20%", borderLeft: "none" }}>
                                                                            {data.publishedData[key]}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }) : 'empty Data'}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div style={{ width: "50%" }}>
                                                    <div>Metrics Data Received from API:</div>
                                                    <table className="table table-bordered" style={{ background: "white !important" }}>
                                                        <tbody style={{ border: "none" }}>
                                                            {data.usageAPIData ? Object.keys(data.usageAPIData).map((key: any, index: any) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td style={{ width: "80%", borderRight: "none" }}>
                                                                            {key}
                                                                        </td>
                                                                        <td style={{ width: "20%", borderLeft: "none" }}>
                                                                            {data.usageAPIData[key]}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }) : "empty Data"}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            );
                        })}
                    </Accordion></div> : "No Data Available" : <div style={{ height: "10rem", margin: "10px 3rem" }}> <LoadingState text="Loading" /></div>}
            </div>
        </Box>
    </Box>
}


export default PhantomComponent