import { ContentCopy } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import ClipBoard from "./ClipBoard";

interface Props {
    text: string,
    fontSize?: number,
    color?: string,
}
const ContentCopyIcon = (props: Props) => {
    const { text, fontSize, color = '#000' } = props
    return (
        <ClipBoard text={text}>
            <Tooltip title="copy">
            <IconButton size='small'>
                <ContentCopy sx={{ color: color, fontSize: fontSize ? fontSize : 16 }} fontSize="small" />
            </IconButton>
            </Tooltip>
        </ClipBoard>
    )
}

export default ContentCopyIcon;
