import { Done } from "@mui/icons-material";
import { Box, Chip } from "@mui/material"

const Options = [
    { value: '86400000', label: '1 day' },
    { value: '604800000', label: '7 days' },
    { value: '2592000000', label: '30 days' },
];

interface Props {
    selectedTime: string,
    setSelectedTime: (time: string) => void
}

const TimeRangeSelector = (props: Props) => {
    const { selectedTime, setSelectedTime } = props;
    const onClick = (time: string) => () => {
        setSelectedTime(time)
    }
  return (
    <Box display="flex" justifyContent="end">
        {
            Options?.map(o => (
                <Chip 
                    label={o.label} 
                    onClick={onClick(o.value)}
                    variant={selectedTime === o.value ? 'filled': 'outlined'}
                    color="primary"
                    icon={selectedTime === o.value ? <Done /> : undefined}
                    sx={{ mr: 0.5 }}
                    size="small"
                />
            ))
        }
    </Box>
  )
}

export default TimeRangeSelector