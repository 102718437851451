import { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { AddBox } from "@mui/icons-material";
import TableRowEmptyState from "../../components/TableRowEmptyState";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import Pagination, { usePagination } from "../../components/Pagination";
import RenderElement from "../../components/RenderElement";
import { useStore } from "../../store";
import AddSubscriptionFormModal from "./AddSubscriptionFormModal";
import { toast } from "../../components/Toast";
import ConfirmBox from "../../components/confirm/confirm";
import { Delete } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import AppSidebar from "../../routes/AppSidebar";
import { auth } from "../../config/firebase";
import metricsService from "../../services/metrics.service";
import subscriptionService from "../../services/subscriptions.service";

const formatter = new Intl.NumberFormat("en-US", {
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 3, // (causes 2500.99 to be printed as $2,501)
});

const Subscriptions = () => {
  const [apiData, setApiData] = useState<any>([]);
  const [openAddSubscriptionModal, setOpenAddSubscriptionModal] =
    useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<any>([]);
  const [subscription, setSubscription] = useState<any>([]);
  const [deleteSubscriptionPopup, setDeleteSubscriptionPopup] = useState(false);
  const { totalCount, setTotalCount, pageNumber, setPageNumber } =
    usePagination({});
  const {
    globalState: { selectedSubscriptionId },
    dispatch,
  } = useStore();
  const [loading, setLoading] = useState(false);
  const { tenantId } = useParams();

  useEffect(() => {
    getSubscriptions(pageNumber,true);
  }, [pageNumber]);
  const getSubscriptions = async (
    pageNumber: number,
    loadingState?: boolean
  ) => {
    try {
      if (loadingState) setLoading(true);
      if (tenantId) {
        const response = await subscriptionService.getAllTenantSubscriptions(
          tenantId,
          pageNumber,
          10
        );
        const subscriptions = response.data.subscriptions;
        const idArray = subscriptions.map(
          (subscription: any) => subscription.subscription_id
        );
        const idString = idArray.join(",");
        const metrics: any = await getMetricsData(idString);
        const getSubscriptionMetricsData = subscriptions.map(
          (subscription: any) => {
            const subMetrics = metrics.find(
              (metric: any) =>
                metric.subscriptionId === subscription.subscription_id
            );
            return { ...subscription, ...subMetrics };
          }
        );
        setTotalCount(response.data.totalCount);
        setApiData(getSubscriptionMetricsData);
      }
      if (loadingState) setLoading(false);
    } catch (error) {
      if (loadingState) setLoading(false);
      console.error("Error:", error);
    }
  };

  const getMetricsData = async (subscriptionId: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await metricsService.getSubscriptionMetrics(
          subscriptionId
        );
        const data = response.data;
        resolve(data.details);
      } catch (error) {
        resolve(null);
        console.error("Error:", error);
      }
    });
  };

  // const addSubscription = (Subscription: any) => {
  //     createSubscription(Subscription?.subscriptionName, Subscription?.type)
  // }
  // const createSubscription = async (subscriptionName: string, type: string) => {
  //     try {
  //         console.log("hey", subscriptionName, type)
  //         //create subscription api
  //         //   await authService.createSubscriptions(selectedSubscriptionId, { email, password, role });
  //         setOpenAddSubscriptionModal(false);
  //         toast.success(dispatch, 'Subscription is getting created!');
  //         getSubscriptions(tenantId, pageNumber, true);
  //     } catch (error: any) {
  //         const errorMsg = error?.response?.data?.message || 'Something went wrong, please try again';
  //         setOpenAddSubscriptionModal(false);
  //         toast.error(dispatch, errorMsg);
  //     }
  // }
  // const getSubscriptions = async (tenantId: string | undefined, pageNumber: number, loadingState?: boolean) => {
  //     try {
  //         if (loadingState) setLoading(true);
  //         // const resp = await registryService.getSubscription(selectedSubscriptionId, pageNumber, 10);
  //         // setSubscription(resp.data?.deviceSubscription);
  //         // setTotalCount(resp.data?.totalCount);
  //         setSubscription(data);
  //         setTotalCount(10);
  //         if (loadingState) setLoading(false);
  //     } catch (error) {
  //         if (loadingState) setLoading(false);
  //     }
  // }

  // useEffect(() => {
  //     console.log("id", tenantId)
  //     getSubscriptions("", pageNumber, true);
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pageNumber, selectedSubscriptionId])

  const SubscriptionTableHeader = [
    { key: "id", title: "Subscription ID", width: "20%" },
    {
      key: "no_of_devices",
      title: "No of Devices",
      width: "16%",
      align: "left",
    },
    { key: "message_size", title: "Message Size (Bytes)", width: "16%", align: "left" },
    // { key: 'number_of_users', title: 'Users Count', width: '16%', align: 'left' },
    {
      key: "totalBillableBytes",
      title: "Billable Bytes",
      width: "16%",
      align: "left",
    },
    {
      key: "billableMessageSize",
      title: "Billable Message Size",
      width: "16%",
      align: "left",
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <AppSidebar />
      <Box sx={{ flexGrow: 1 }}>
        <Toolbar sx={{ borderBottom: "1px solid #cdcdcd" }} variant="dense">
          <Typography
            variant="h6"
            className="text-truncate"
            width="60vw"
            fontWeight={500}
            component="h1"
          >
            Tenant Details
          </Typography>
        </Toolbar>
        <Toolbar variant="dense">
          <Breadcrumbs separator="›" aria-label="breadcrumb" maxItems={2}>
            <Link
              to="/"
              style={{ color: "1px solid #cdcdcd", textDecoration: "none" }}
            >
              <Typography color="text.primary">Tenants</Typography>
            </Link>
            <Link
              to={`/tenants/${tenantId}/subscriptions`}
              style={{ color: "1px solid #cdcdcd", textDecoration: "none" }}
            >
              <Typography
                style={{
                  backgroundColor: "#ebebeb",
                  color: "#cb6100",
                  padding: "2px 10px",
                  borderRadius: "30px",
                }}
              >
                {tenantId}
              </Typography>
            </Link>
          </Breadcrumbs>
        </Toolbar>
        <Box sx={{ padding: "1.5rem" }}>
          {/* <Typography variant="h6" marginBottom={3} className="text-truncate" width="60vw" fontWeight={500} component="h6">Tenent ID : KORE wireless</Typography> */}
          <Grid container>
            <Grid item sm={6}>
              <Typography
                variant="h6"
                className="text-truncate"
                paddingBottom={2}
                width="60vw"
                fontWeight={500}
                component="h1"
              >
                Tenant ID : {tenantId}
              </Typography>
              <Divider sx={{ opacity: 1 }} />
              <Grid container>
                <Grid item sm={8}>
                  <Typography fontWeight="500" variant="subtitle1">
                    Number of Subscriptions:
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <Typography color="GrayText" variant="body1">
                    {totalCount}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ opacity: 1, marginBottom: 1 }} />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ padding: ".5rem 1.5rem" }}>
          <Typography
            variant="h6"
            marginBottom={3}
            className="text-truncate"
            width="60vw"
            fontWeight={500}
            component="h1"
          >
            Subscriptions
          </Typography>
        </Box>
        <TableContainer>
          <Table
            sx={{ tableLayout: "fixed", marginBottom: "25px" }}
            size="small"
            aria-label="Subscription table"
          >
            <TableBody>
              <TableRow>
                {SubscriptionTableHeader.map((headerCell: any) => (
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      fontSize: "15px",
                      backgroundColor: "#ebebeb",
                    }}
                    key={headerCell.key}
                    align={headerCell.align || "left"}
                    width={headerCell.width}
                  >
                    {headerCell.title}
                  </TableCell>
                ))}
              </TableRow>
              <TableRowEmptyState
                show={apiData?.length === 0}
                message="No Subscriptions"
                loading={loading}
                loadingText="Loading Subscriptions"
                colSpan={6}
              />
              {apiData?.map((row: any) => {
                return (
                  <TableRow key={row?.subscription_id}>
                    <TableCell className="text-truncate">
                      <Link
                        to={`${row?.subscription_id}`}
                        style={{
                          color: "1px solid #cdcdcd",
                          textDecoration: "none",
                        }}
                      >
                        <Typography color="text.primary">
                          {row?.subscription_id}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      {formatter.format(row?.noOfDevices)}
                    </TableCell>
                    <TableCell align="left">
                      {formatter.format(row?.messageSize)}
                    </TableCell>
                    {/* <TableCell align='left'>{Math.floor(Math.random() * 12) + 1}</TableCell> */}
                    <TableCell align="left">
                      {formatter.format(
                        Math.floor(
                            row?.billableMessageSize
                        )
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {formatter.format(
                        Math.round(row?.billableMessageSize / 1000000)
                      )}{" "}
                      MB
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <RenderElement show={totalCount > 10}>
          <Pagination
            count={Math.ceil(totalCount / 10)}
            page={pageNumber}
            onChange={(e, page: number) => setPageNumber(page)}
          />
        </RenderElement>
      </Box>
      {/* <AddSubscriptionFormModal
                open={openAddSubscriptionModal}
                handleClose={() => setOpenAddSubscriptionModal(false)}
                onAddSubscription={addSubscription}
            /> */}
    </Box>
  );
};

export default Subscriptions;
