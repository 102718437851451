import { Navigate, Route, Routes } from 'react-router-dom';
import { Login } from '../pages';
import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';
import { Box, CircularProgress } from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../config/firebase';
import TCPDevices from '../pages/Devices/TCP Devices';
import PhantomComponent from '../pages/Phantom/PhantomComponent';
import ResetPassword from '../pages/reset-password/ResetPassword';
import SubscriptionTab from '../pages/tenants/SubscriptionTab';
import Subscriptions from '../pages/tenants/subscriptions';
import Tenant from '../pages/tenants/tenants';
import Users from "../pages/users/users";

const AppRoutes = () => {

  const [user, loading] = useAuthState(auth);
  if (loading) {
    return (
      <Box sx={{ color: 'grey.500', display: 'flex', height: 'calc(100vh - 64px)' }}  >
        <CircularProgress color="primary" sx={{ margin: 'auto' }} />
      </Box>
    )
  }
  
  return (
    <Routes>
       <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/phantom" element={<PrivateRoute><PhantomComponent /></PrivateRoute>} />
        <Route path="/tenants" element={<PrivateRoute><Tenant/></PrivateRoute>} />
        <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
        <Route path="/devices" element={<PrivateRoute><TCPDevices/></PrivateRoute>}/>
        <Route path="/tenants/:tenantId/subscriptions" element={<PrivateRoute><Subscriptions/></PrivateRoute>} />
        <Route path="/tenants/:tenantId/subscriptions/:selectedSubscriptionId" element={<PrivateRoute><SubscriptionTab/></PrivateRoute>} />
        {/* <Route path="/tenants/:tenantId/subscriptions/:selectedSubscriptionId/users" element={<PrivateRoute><SubscriptionTab/></PrivateRoute>} />
        <Route path="/tenants/:tenantId/subscriptions/:selectedSubscriptionId/details" element={<PrivateRoute><SubscriptionData/></PrivateRoute>} /> */}
        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="/set-password/:token" element={<PublicRoute><ResetPassword /></PublicRoute>} />
    </Routes>
  )
}

export default AppRoutes;
