import { Email, Google, VpnLock } from "@mui/icons-material";
import { Microsoft } from "../components/Icons";

export const Providers = [
  {
    id: "password",
    title: "Email / Password",
    icon: Email,
    iconColor: "",
    disabled: true,
  },
  {
    id: "google",
    title: "Google",
    icon: Google,
    iconColor: "rgb(66, 133, 244)",
    disabled: true,
  },
  { id: "microsoft", title: "Microsoft", icon: Microsoft, disabled: true },
  // { id: "facebook", title: 'Facebook', icon: Facebook, iconColor: 'rgb(59, 89, 152)'  },
  // { id: "twitter", title: 'Twitter', icon: Twitter, iconColor: 'rgb(85, 172, 238)' },
  { id: "saml", title: "SAML", icon: VpnLock, iconColor: "#000" },
];

export const permissions = {
  CreateUser: "captainConsole.users.create",
  CreateAdmin: "captainConsole.users.createAdmin",
  ListUser: "captainConsole.users.list",
  CreatePartnerTenant: "captainConsole.tenants.createPartnerTenant",
  ListTenant: "captainConsole.tenants.list",
  ListSubscriptions: "captainConsole.subscriptions.list",
  GetSubscriptionDetails: "captainConsole.subscriptions.get",
  ListPhantomResults: "captainConsole.phantom.list",
  GetMetrics: "captainConsole.metrics.get",
  ListRoles: "captainConsole.roles.get",
  GetTenantDetails: "captainConsole.tenants.get",
  DeleteUser: "captainConsole.users.delete",
  updateTenant: "captainConsole.tenant.update",
};

export const Roles = {
  CaptainConsoleAdmin: [
    "captainConsole.users.list",
    "captainConsole.users.create",
    "captainConsole.users.createAdmin",
    "captainConsole.tenants.createPartnerTenant",
    "captainConsole.tenants.list",
    "captainConsole.subscriptions.list",
    "captainConsole.subscriptions.get",
    "captainConsole.phantom.list",
    "captainConsole.metrics.get",
    "captainConsole.roles.get",
    "captainConsole.tenants.get",
    "captainConsole.users.delete",
    "captainConsole.tenant.update",
  ],
  CaptainConsoleUser: [
    "captainConsole.users.list",
    "captainConsole.tenants.list",
    "captainConsole.subscriptions.list",
    "captainConsole.subscriptions.get",
    "captainConsole.phantom.list",
    "captainConsole.metrics.get",
    "captainConsole.roles.get",
    "captainConsole.tenants.get",
  ],
};


