import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
  IconButton,
  Stack
} from "@mui/material";
import { AddBox, PersonAdd, Delete } from "@mui/icons-material";

import TableRowEmptyState from "../../components/TableRowEmptyState";
import Pagination, { usePagination } from "../../components/Pagination";
import RenderElement from "../../components/RenderElement";
import { useStore } from "../../store";
import AppSidebar from "../../routes/AppSidebar";
import userService from "../../services/users.service";
import AddUserFormModal from "./AddUserFormModal";
import { toast } from "../../components/Toast";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../config/firebase';
import { permissions } from "../../global/constants";
import { User } from '../../types';
import ConfirmBox from "../../components/confirm/confirm";

const Users = () => { 
  const [apiData, setApiData] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>();
  const [activeMenuId, setActiveMenuId] = useState('');
  const { totalCount, setTotalCount, pageNumber, setPageNumber } =
    usePagination({});
    const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const {
    globalState: { userPermissions }, dispatch
  } = useStore();
  const [user] = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  // const [queryParams] = useSearchParams();
  // const subscription = queryParams.get('subscription') || '';
  // 
  const addUser = (user: any) => {
    createUser(user?.email, user?.password, user?.role )
  }

  const createUser = async(email: string, password: string, role: string) => {
    try {
      await userService.createUser({ email, password, role });
      setOpenAddUserModal(false);
      toast.success(dispatch, 'Access granted');
      getUsers(pageNumber);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message || 'Something went wrong, please try again';
      setOpenAddUserModal(false);
      toast.error(dispatch, errorMsg);
    }
  }


  const getUsers = async (pageNumber: number, loadingState?: boolean) => {
    try {
      if (loadingState) setLoading(true);
      const resp = await userService.getAllUsers(pageNumber,10);
      setApiData(resp.data.users);
      setTotalCount(resp.data?.totalCount);
      if (loadingState) setLoading(false);
    } catch (error) {
      if (loadingState) setLoading(false);
    }
  };

  const deleteUser = async (uid: string) => {
    try {
        setProcessing(true);
        await userService.deleteUser(uid);
        setSelectedUser(null);
        setProcessing(false);
        setActiveMenuId('');
        toast.success(dispatch, 'User deleted.');
        refreshList(pageNumber,true)
    } catch (error: any) {
        setActiveMenuId('');
        setSelectedUser(null);
        const errMsg = error?.response?.data?.message || 'Delete user failed'
        toast.error(dispatch, errMsg);
    }
}

  const onPartnerCreationComplete = () => {
    refreshList(pageNumber,true);
  }
   
  const refreshList = (pageNumber: number, loadingState?: boolean) => {
    getUsers(pageNumber,loadingState);
  }

  useEffect(() => {
    getUsers(pageNumber, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const tenantTableHeader = [
    { key: "Email", title: "Email", width: "25%" },
    { key: "Role", title: "Role", width: "25%" },
    { key: "Created By", title: "Created By", width: "25%", align: "left" },
    {
      key: "Created On",
      title: "Created On",
      width: "25%",
      align: "left",
    },
    { key: 'action', title: '', width: '24%'}
  ];
  const deletePermission = userPermissions.includes(permissions.DeleteUser)
  return (
    <Box sx={{ display: "flex" }}>
      <AppSidebar />
      <Box sx={{ flexGrow: 1 }}>
        <Toolbar variant="dense">
          <Typography
            variant="h6"
            className="text-truncate"
            fontWeight={500}
            component="h1"
          >
            Users
          </Typography>
          <RenderElement show={userPermissions.includes(permissions.CreateUser)}>
          <Button sx={{ marginLeft: '3rem' }}  onClick={() => { setOpenAddUserModal(true) }}>
                        <PersonAdd sx={{ mr: .5 }} />
                        GRANT ACCESS
                    </Button>
                    </RenderElement>
        </Toolbar>
        <Divider sx={{ opacity: 1 }} />
        {/* <Toolbar sx={{ borderBottom: '1px solid #cdcdcd' }} variant="dense">
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <RouterLink to="/" style={{ color: '1px solid #cdcdcd', textDecoration: 'none' }}>
                            <Typography style={{ backgroundColor: '#ebebeb', color: '#cb6100', padding: '2px 10px', borderRadius: '30px' }}>Tenants</Typography>
                        </RouterLink>
                    </Breadcrumbs>
                </Toolbar> */}
        <TableContainer>
          <Table
            sx={{ tableLayout: "fixed" }}
            size="small"
            aria-label="Tenant table"
          >
            <TableBody>
              <TableRow>
                {tenantTableHeader.map((headerCell: any) => (
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      fontSize: "15px",
                      backgroundColor: "#ebebeb",
                    }}
                    key={headerCell.key}
                    align={headerCell.align || "left"}
                    width={headerCell.width}
                  >
                    {headerCell.title}
                  </TableCell>
                ))}
              </TableRow>
              <TableRowEmptyState
                show={apiData?.length === 0}
                message="No Users"
                loading={loading}
                loadingText="Loading Users"
                colSpan={6}
              />
              {apiData?.map((row: any) => {
                return (
                  <TableRow key={row?.tenantId}>
                    <TableCell className="text-truncate">
                      {row?.email}
                    </TableCell>
                    <TableCell className="text-truncate">
                      {row?.role}
                    </TableCell>
                    <TableCell className="text-truncate">
                      {row?.created_by}
                    </TableCell>
                    {/* <TableCell className="text-truncate">
              {row?.tenent_id}
          </TableCell> */}
                    <TableCell align="left">{row?.created_on  ? new Date(row?.created_on)?.toLocaleString() : ""}</TableCell>
                    <TableCell sx={{ paddingY: 0 }}>
                                        <RenderElement show={user?.uid !== row?.uid}>
                                            <Stack direction="row" alignItems="center">
                                                <RenderElement show={deletePermission && (row?.role === 'CaptainConsoleAdmin') ? userPermissions.includes(permissions.DeleteUser) : true}>
                                                    <IconButton sx={{ padding: .5, color: "#000" }} onClick={()=> setSelectedUser(row)}>
                                                        <Delete sx={{ fontSize: '1.1rem' }} />
                                                    </IconButton>
                                                </RenderElement>
                                                </Stack>
                                                </RenderElement>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <RenderElement show={totalCount > 10}>
          <Pagination
            count={Math.ceil(totalCount / 10)}
            page={pageNumber}
            onChange={(e, page: number) => setPageNumber(page)}
          />
        </RenderElement>
        </TableContainer>
        <AddUserFormModal 
         open={openAddUserModal}
         handleClose={()=> setOpenAddUserModal(false)}
         onAddUser={addUser}/>
         <ConfirmBox
        title={`Delete ${selectedUser?.email} ?`}
        contentText="Once you delete an account, all of its details are removed and cannot be recovered."
        cancelText="Cancel"
        okText="Delete"
        open={!!selectedUser && !activeMenuId}
        handleClose={()=> setSelectedUser(null)}
        handleOk={()=> deleteUser(selectedUser?.uid || '')}
    />
      </Box>
    </Box>
  );
};

export default Users;
