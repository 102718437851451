import { Button, Dialog, DialogContent, DialogTitle, Link, Stack } from '@mui/material';
import validator from 'validator';
import { FormEvent, useEffect, useState } from 'react'
import Controls from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';
import LoadingButton from '../../LoadingButton';
import rolesService from '../../services/roles.service';
import { permissions } from '../../global/constants';
import { Help } from '@mui/icons-material';
import { useStore } from '../../store';

interface Props {
    open: boolean,
    handleClose: () => void,
    onAddUser: (user: any) => void
}

const AddUserFormModal = (props: Props) => {
    const { open, handleClose, onAddUser } = props;
    const { globalState: {  tenantDetails,userPermissions } } = useStore();
    // const [userExist, setUserExist] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [roles, setRoles] = useState<any[]>([]);
    const [allUserRoles, setAllUserRoles] = useState<any[]>([]);
    // const [userExistCheckDone, setUserExistCheckDone] = useState(false);

    let validDomains = ["korewireless.com"];
    const validate = (fieldValues = values ) => {
        let temp = { ...errors }
		if (validator.isEmpty(fieldValues?.email)) {
			temp.email = "This field is required."
		}else if(!validator.isEmail(fieldValues?.email)) {
			temp.email = "Invalid email."
		}
        // else if(!validDomains.includes(fieldValues?.email.split('@')[1])) {
        //     // temp.email = "Email should match your domain or your partner domain"
        // }
        else {
            temp.email = ""
        }

        setErrors({...temp})

        // return Object.values(temp).every(x => x === "")
        return temp?.email === ""
    }

    const { values, errors, handleInputChange, setErrors, setValues, resetForm } = useForm({}, true, validate);

    const submitHandler = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
        if(validate()) {
            onAddUser(values);
            setProcessing(true);
        }
	}

    const onCancel = () => {
        handleClose();
    }

    useEffect(() => {
        if(open === false) {
            resetForm();
            setProcessing(false);
            // setUserExist(true);
            // setUserExistCheckDone(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    // useEffect(()=>{
    //     if(validator.isEmail(values?.email || '')) {
    //         let emailDomain = values?.email?.split('@')[1];
    //         if(emailDomain !== "korewireless.com" && validDomains.includes(emailDomain)) {
    //             const rolesOptions = allUserRoles?.filter((r: any) => !(['TenantAdmin', 'Admin'].includes(r?.id)));
    //             setRoles(rolesOptions);
    //             if(values?.role === 'TenantAdmin') {
    //                 setValues({ ...values, role: ''})
    //             }
    //         }else {
    //             let rolesOptions = [...allUserRoles]
    //             if(!userPermissions.includes(permissions.CreateUser)) {
    //                 rolesOptions = allUserRoles.filter((r: any) => r.id !== 'TenantAdmin');
    //             }
    //             setRoles(rolesOptions);
    //         }
    //     }
    // }, [values?.email]);

    const getRoles = async () => {
        try {
          const resp = await rolesService.listRoles();
          if(resp?.data?.roles) {
            let rolesOptions = resp?.data?.roles;
            rolesOptions = rolesOptions.map((role: any) => ({ id: role?.role, title: role?.role}));
            setAllUserRoles([...rolesOptions]);
            setRoles(rolesOptions);
          }
        } catch (error) {
          
        }
    }
    
    useEffect(() => {
        getRoles()
    }, [])
    

    return (
        <Dialog open={open} onClose={onCancel} fullWidth maxWidth="xs">
            <DialogTitle>Grant access</DialogTitle>
            <DialogContent>
                {/* <Typography marginBottom={3} variant="body2">Users will be added using the email/password identity provider.</Typography> */}
                <Form onSubmit={submitHandler}>
                    <Controls.Input
                        name="email"
                        label="Email"
                        value={values?.email || ''}
                        onChange={handleInputChange}
                        required
                        fullWidth
                        error={!!errors['email']}
                        helperText={errors['email']}
                        sx={{ my: 3 }}
                    />
                    <Controls.Select
                        name="role"
                        label="Role"
                        value={values?.role || ''}
                        onChange={handleInputChange}
                        required
                        fullWidth
                        options={roles}
                        error={!!errors['role']}
                        helperText={errors['role']}
                        sx={{ mb: 3 }}
                    />
                    {/* <Link href={DocLinks.IAMRoles} variant="body2" target="_blank" sx={{ display: 'flex', alignItems: 'center'}}>
                        <Help fontSize='small' color="action" sx={{ mr: 1 }} />
                        Understanding OmniCore Roles
                    </Link> */}
                    <Stack direction="row" marginTop={4} justifyContent="flex-end" spacing={2}>
                        <Button onClick={onCancel}>Cancel</Button>
                        <LoadingButton type="submit" variant="contained" 
                            loading={processing}
                            disabled={processing}
                        >Add</LoadingButton>
                    </Stack>

                </Form>
            </DialogContent>
        </Dialog>    
    )
}

export default AddUserFormModal;
