import { AppBar, Divider, Grid, Toolbar, Typography } from "@mui/material";

import { Link } from "react-router-dom";

import { auth, signOutHandler } from '../../config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

import { ThemeProvider, createTheme } from '@mui/material';

import Logo from '../../assets/kore_logo.png';

import { Box } from "@mui/system";

import AccountMenu from "./AccountMenu";

import RenderElement from "../RenderElement";
import { useStore } from "../../store";

import authService from "../../services/auth.service";
import { useEffect, useState } from "react";
import { Roles } from "../../global/constants";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: '#f58320'
    },
    secondary: {
      main: "#CB6100"
    }
  }
});

const Header = () => {

  const [user] = useAuthState(auth);

  const { globalState: { tenantDetails }, dispatch  } = useStore()

 

  const getUserPermissions = async () => {
      type roleType = 'CaptainConsoleAdmin' | 'CaptainConsoleUser';
      await authService.setAccessControl();
      const tokenResult: any = await user?.getIdTokenResult(true);
      const role: roleType = tokenResult?.claims?.role
      dispatch({ type: 'LOGIN_COMPLETE', payload: true})
      const permissions = Roles[role]
      dispatch({ type: 'SET_USER_PERMISSIONS', payload: permissions })
      dispatch({ type: 'SET_USER_ROLE', payload: role });
  }

  useEffect(() => {
    if (user) {
      getUserPermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid])

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" sx={{ borderBottom: '1px solid #cdcdcd' }}>
        <Toolbar>

          <Box display="flex" sx={{ flexGrow: 1 }}>
            <Link to="/tenants" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
              <img src={Logo} alt="Logo" height={40} />
              <Divider variant="middle" orientation="vertical" sx={{ mx: 1.5, height: '80%', borderRightWidth: 'medium' }} />
              {/* <img src={captain} style={{ height: '4rem',width:'7rem' }} alt="OmniCore" height={20} /> */}
              <Typography style={{ color: '#FF8312', padding: '2px 10px', fontSize: '1.5rem', fontFamily: "Roboto" ,fontWeight:'600' }}>Captain's Console</Typography>

            </Link>
            {/* <RenderElement show={!!user}>
              <Grid container flexGrow={1} ml={6}>
                <Grid item sm={4}>
                  <SubSelect
                    options={[
                      { id: 1, tenant: 'korewireless-development', title: 'Development', disabled: false },
                      { id: 2, tenant: 'korewireless-demo', title: 'Demo', disabled: false },
                      { id: 3, tenant: 'korewireless-production', title: 'Production', disabled: false },
                    ]}
                    defaultValue={1}
                  />
                </Grid>
              </Grid>
            </RenderElement> */}


          </Box>
          <RenderElement show={!!user}>

            <AccountMenu user={user} onLogout={signOutHandler} tenantDetails={tenantDetails} />
          </RenderElement>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}

export default Header;
