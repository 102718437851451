export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const stringToColor = (string: string) => {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }

export const truncateTextInMiddle = (value: string, first: number = 10, last: number = 10) => `${value.slice(0, first)}...${value.slice(-last)}`;

export const truncateText = (value: string, count: number = 30) => `${value.slice(0, count)}...`;

export const getDomainFromEmail = (email: string) => {
  let domain = email.split('@')[1];
  domain = domain.replace('.', '-');
  return domain;
}

export const daysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
}

export const getCurrentMonthDays = () => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return new Date(year, month, 0).getDate();
}