import adminAPI from "./adminAPI";

class AuthService {
  setAccessControl = () => {
    return adminAPI.patch(`/auth/set-access-control`);
  };
  getTenantDetails = (tenantId: string) => {
    return adminAPI.patch(`/tenant-details?id=${tenantId}`);
  };

}

export default new AuthService();
